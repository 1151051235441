





































import Vue from 'vue'
import ManageAction from '@/components/admin/ManageAction.vue'
import { secureAjax } from '@/utils/axios.helpers'
import { Scope } from '@/utils/auth0.consts'

export default Vue.extend({
  name: 'AdminManageGallery',

  components: { ManageAction },

  data(): { images: Array<string> } {
    return {
      images: [],
    }
  },

  methods: {
    async uploadImage({ currentTarget }: { currentTarget: HTMLInputElement }) {
      const image = currentTarget.files[0]
      const formData = new FormData()
      formData.append('image', image)

      try {
        const { data } = await secureAjax(
          {
            method: 'POST',
            url: '/gallery',
            data: formData,
          },
          Scope.MANAGE_ADMIN
        )

        this.invokeGetGallery()
        alert(`Obrázek '${data.image}' byl úspěšně nahrán`)
      } catch (e) {
        console.error(e)
        alert('Při nahrávání obrázku nastala chyba')
      }
    },

    async deleteImage(image: string) {
      try {
        await secureAjax(
          {
            method: 'DELETE',
            url: '/gallery',
            data: { image },
          },
          Scope.MANAGE_ADMIN
        )

        this.invokeGetGallery()
        alert(`Obrázek '${image}' byl odstraněn`)
      } catch (e) {
        console.error(e)
        alert('Při odstraňování obrázku nastala chyba')
      }
    },

    async invokeGetGallery() {
      try {
        const { data } = await secureAjax(
          {
            method: 'GET',
            url: '/gallery',
          },
          Scope.MANAGE_ADMIN
        )
        data && (this.images = data.images || [])
      } catch (e) {
        console.error(e)
        alert('Při získávání galerie nastala chyba')
      }
    },
  },

  created() {
    this.invokeGetGallery()
  },
})
