

















import ComponentLoader from './ComponentLoader.vue'
import { PrettyDate } from '@/mixins/date.mixin'
import mixins from 'vue-typed-mixins'

export default mixins(PrettyDate).extend({
  name: 'DescriptionBox',

  props: {
    date: {
      type: String,
      required: false,
    },

    title: {
      type: String,
      required: false,
      default: '',
    },

    description: {
      type: String,
      required: true,
    },

    hasTriangle: {
      type: Boolean,
      default: true,
    },

    noPadding: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ComponentLoader,
  },
})
