var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"pure-form pure-form-aligned"},[_c('fieldset',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('div',{staticClass:"pure-control-group"},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"title"}},[_vm._v("Titulek")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"}],attrs:{"id":"title","type":"text","placeholder":"Titulek"},domProps:{"value":(_vm.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.title=$event.target.value}}}),_c('span',{staticClass:"pure-form-message-inline"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"pure-control-group"},[_c('validation-provider',{attrs:{"name":"icon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"icon"}},[_vm._v("Ikona")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.icon),expression:"icon"}],attrs:{"id":"icon"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.icon=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.icons),function(option,index){return _c('option',{key:index,domProps:{"value":option.value}},[_vm._v("\n              "+_vm._s(option.text)+"\n            ")])}),0),(_vm.icon)?_c('span',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":_vm.icon}})],1):_vm._e(),_c('span',{staticClass:"pure-form-message-inline"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"pure-control-group"},[_c('label',{attrs:{"for":"is-pre-selected"}},[_vm._v("Předvybrat")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isPreSelected),expression:"isPreSelected"}],attrs:{"id":"is-pre-selected","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.isPreSelected)?_vm._i(_vm.isPreSelected,null)>-1:(_vm.isPreSelected)},on:{"change":function($event){var $$a=_vm.isPreSelected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isPreSelected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isPreSelected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isPreSelected=$$c}}}})]),_c('div',{staticClass:"pure-control-group"},[_c('validation-provider',{attrs:{"name":"lang","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"lang"}},[_vm._v("Jazyk")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.lang),expression:"lang"}],attrs:{"id":"lang"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.lang=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.languages),function(option,index){return _c('option',{key:index,domProps:{"value":option.value}},[_vm._v("\n              "+_vm._s(option.text)+"\n            ")])}),0),_c('span',{staticClass:"pure-form-message-inline"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('markdown-editor',{attrs:{"locale":"cs"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('div',{staticClass:"pure-controls"},[_c('button',{staticClass:"pure-button pure-button-primary",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.sendForm($event)}}},[_vm._v("\n          Submit\n        ")])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }