import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import merge from 'lodash.merge'
import store from '../store'
import { refreshToken } from '../utils/auth0.client'

const hostname = process.env.HOSTNAME || 'http://localhost:3300'

export const ajax: AxiosInstance = axios.create({
  baseURL: `${process.env.NODE_ENV === 'production' ? '' : hostname}/api/v1`,
  timeout: 6000,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
})

export const secureAjax = async (params: AxiosRequestConfig, scope: string) => {
  if (!process.env.VUE_ENABLE_GUEST_MODE) {
    await refreshToken(scope)
  }

  return ajax(
    merge(params, {
      headers: { Authorization: `Bearer ${store.state.user.token}` },
    })
  )
}
