



















import mixins from 'vue-typed-mixins'
import Navigation from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'
import Bar from '@/components/Bar.vue'
import { video } from '@/utils/video.consts'
import { VideoItem } from '@/typings/video.typings'
import { Language } from '@/typings/translations.typings'
import { LangMixin } from '@/mixins/lang.mixin'
import { TranslateResult } from 'vue-i18n'

interface LinkItem {
  path: string
  title: string | TranslateResult
  icon?: string
}

export default mixins(LangMixin).extend({
  name: 'RouteWrapper',

  components: {
    HeaderBar: Bar,
    Navigation,
    Foot: Footer,
  },

  computed: {
    title(): string {
      let name = this.$route.name
      if (name === 'index') {
        name = 'whatDoWeDo'
      }
      return name
    },

    description(): string {
      return `bar.${this.$route.name}`
    },

    video(): VideoItem {
      if (['about', 'index'].includes(this.$route.name)) {
        return video.about
      }
    },

    link(): LinkItem {
      if (this.$route.name === 'career' && this.lang === Language.CS) {
        return {
          path: 'https://www.ferrit.cz/cs/kariera',
          title: this.$t('ferritCareer'),
          icon: 'plus-circle',
        }
      }
    },
  },
})
