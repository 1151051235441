import { extend, localize } from 'vee-validate'
import cs from 'vee-validate/dist/locale/cs.json'

if (typeof window !== 'undefined') {
  const required = require('vee-validate/dist/rules').required
  const email = require('vee-validate/dist/rules').email

  extend('required', required)
  extend('email', email)
}

localize('cs', {
  messages: cs.messages,
  names: {
    title: 'titulek',
    icon: 'ikona',
  },
})
