import Vue from 'vue'
import Router from 'vue-router'
import i18n from './i18n'
import { getRouteAliases } from './locale/routes'
import { languageGuard } from './utils/router.helpers'
import { getLock } from './utils/auth0.client'
import { Language } from './typings/translations.typings'
import { ChildRoute } from './typings/router.typings'

// Views
import RouterWrapper from './RouteWrapper.vue'
import AdminWrapper from '@/views/admin/index.vue'
import Admin from '@/views/admin/Default.vue'

// Admin forms
import AdminAddCareer from '@/views/admin/AddCareer.vue'
import AdminAddArticle from '@/views/admin/AddArticle.vue'
import AdminAddBulletin from '@/views/admin/AdminAddBulletin.vue'

import AdminManageCareers from '@/views/admin/ManageCareers.vue'
import AdminManageGallery from '@/views/admin/ManageGallery.vue'
import AdminManageOfficeDocuments from '@/views/admin/ManageOfficeDocuments.vue'
import AdminManageArticles from '@/views/admin/ManageArticles.vue'
import AdminManageBulletins from '@/views/admin/AdminManageBulletins.vue'

import Intranet from '@/views/Intranet.vue'
import IndexView from '@/views/Index.vue'
import About from '@/views/About.vue'
import Career from '@/views/Career.vue'
import News from '@/views/News.vue'
import Products from '@/views/Products.vue'
import Contacts from '@/views/Contacts.vue'
import Curiosity from '@/views/Curiosity.vue'
import ContactPoints from '@/views/ContactPoints.vue'
import Certificates from '@/views/Certificates.vue'
import Reference from '@/views/Reference.vue'
import Servicing from '@/views/Servicing.vue'

// Error views
import Error404 from '@/views/error/404.vue'

Vue.use(Router)

export function createRouter() {
  return new Router({
    mode: 'history',
    scrollBehavior() {
      return { x: 0, y: 0 }
    },
    routes: [
      {
        path: '/',
        redirect: `/${Language.CS}`,
      },
      {
        path: '/logout',
        name: 'logout',
        beforeEnter(...[, , next]) {
          if (typeof document !== 'undefined') {
            // Initialize empty lock, with empty redirect
            const lock = getLock('', '')
            lock.logout({ returnTo: location.origin })
          } else {
            next('/')
          }
        },
      },
      {
        path: '/administrace',
        component: AdminWrapper,
        beforeEnter(...[, , next]) {
          // Always CS
          i18n.locale = Language.CS
          next()
        },
        meta: {
          redirectPath: '/administrace',
        },
        children: [
          {
            path: '',
            name: 'admin',
            component: Admin,
            meta: {
              heading: 'Vítejte v administraci ferrit-electric.cz',
            },
          },
          {
            path: 'pridat-karieru',
            name: 'admin-add-career',
            component: AdminAddCareer,
            meta: {
              heading: 'Přidat kariéru',
            },
          },
          {
            path: 'pridat-novinku',
            name: 'admin-add-article',
            component: AdminAddArticle,
            meta: {
              heading: 'Přidat novinku',
            },
          },
          {
            path: 'pridat-interni-zpravu',
            name: 'admin-add-bulletin',
            component: AdminAddBulletin,
            meta: {
              heading: 'Přidat interní zprávu',
            },
          },
          {
            path: 'sprava-karier',
            name: 'admin-manage-careers',
            component: AdminManageCareers,
            meta: {
              heading: 'Správa kariér',
            },
          },
          {
            path: 'sprava-internich-zprav',
            name: 'admin-manage-bulletins',
            component: AdminManageBulletins,
            meta: {
              heading: 'Správa interních zpráv',
            },
          },
          {
            path: 'sprava-novinek',
            name: 'admin-manage-articles',
            component: AdminManageArticles,
            meta: {
              heading: 'Správa novinek',
            },
          },
          {
            path: 'sprava-galerie',
            name: 'admin-manage-gallery',
            component: AdminManageGallery,
            meta: {
              heading: 'Správa galerie',
            },
          },
          {
            path: 'sprava-dokumentu',
            name: 'admin-manage-documents',
            component: AdminManageOfficeDocuments,
            meta: {
              heading: 'Správa dokumentů',
            },
          },
        ],
      },
      {
        path: '/intranet',
        redirect: `/intranet/${Language.CS}`,
      },
      {
        path: '/intranet/:lang',
        name: 'intranet',
        component: Intranet,
        beforeEnter: (...[to, , next]) => languageGuard(to, 'intranet/', next),
        meta: {
          redirectPath: '/intranet',
        },
      },
      {
        path: '/:lang',
        component: RouterWrapper,
        beforeEnter: (...[to, , next]) => languageGuard(to, '', next),
        children: [
          {
            path: '',
            name: ChildRoute.INDEX,
            component: IndexView,
          },
          {
            path: ChildRoute.ABOUT,
            name: ChildRoute.ABOUT,
            alias: getRouteAliases(ChildRoute.ABOUT),
            component: About,
          },
          {
            path: ChildRoute.CAREER,
            name: ChildRoute.CAREER,
            alias: getRouteAliases(ChildRoute.CAREER),
            component: Career,
          },
          {
            path: ChildRoute.NEWS,
            name: ChildRoute.NEWS,
            alias: getRouteAliases(ChildRoute.NEWS),
            component: News,
          },
          {
            path: ChildRoute.PRODUCTS,
            name: ChildRoute.PRODUCTS,
            alias: getRouteAliases(ChildRoute.PRODUCTS),
            component: Products,
          },
          {
            path: ChildRoute.CONTACTS,
            name: ChildRoute.CONTACTS,
            alias: getRouteAliases(ChildRoute.CONTACTS),
            component: Contacts,
          },
          {
            path: ChildRoute.CURIOSITY,
            name: ChildRoute.CURIOSITY,
            alias: getRouteAliases(ChildRoute.CURIOSITY),
            component: Curiosity,
          },
          {
            path: ChildRoute.CONTACT_POINTS,
            name: ChildRoute.CONTACT_POINTS,
            alias: getRouteAliases(ChildRoute.CONTACT_POINTS),
            component: ContactPoints,
          },
          {
            path: ChildRoute.CERTIFICATES,
            name: ChildRoute.CERTIFICATES,
            alias: getRouteAliases(ChildRoute.CERTIFICATES),
            component: Certificates,
          },
          {
            path: ChildRoute.REFERENCE,
            name: ChildRoute.REFERENCE,
            alias: getRouteAliases(ChildRoute.REFERENCE),
            component: Reference,
          },
          {
            path: ChildRoute.SERVICING,
            name: ChildRoute.SERVICING,
            alias: getRouteAliases(ChildRoute.SERVICING),
            component: Servicing,
          },
          // Fallback route (404)
          {
            path: '*',
            name: 'error404',
            component: Error404,
          },
        ],
      },
    ],
  })
}
