















































































import Bar from '@/components/Bar.vue'
import BoxLink from '@/components/BoxLink.vue'
import { LangMixin } from '@/mixins/lang.mixin'
import mixins from 'vue-typed-mixins'

const tilesImages: { [key in string]: string } = {
  news: 'what-is-new.jpg',
  curiosity: 'curiosity.jpg',
  contactPoints: 'world-presence.jpg',
  contacts: 'extraction.jpg',
}

export default mixins(LangMixin).extend({
  name: 'guidePost',

  data() {
    return { tilesImages }
  },

  computed: {
    mobileTiles() {
      return ['news', 'curiosity', 'contactPoints', 'contacts'].map(
        (it: string) => ({
          title: this.$t(it),
          description: this.$t(`bar.${it}`),
          url: it,
          image: tilesImages[it],
        })
      )
    },
  },

  components: {
    Bar,
    BoxLink,
  },
})
