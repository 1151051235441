





























import Vue from 'vue'
import InlineCircles from '@/components/InlineCircles.vue'

interface LinkCarouselData {
  current: number
}

export default Vue.extend({
  name: 'LinkCarousel',

  components: {
    InlineCircles,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data(): LinkCarouselData {
    return {
      current: 0,
    }
  },
})
