var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pure-g"},[_c('div',{staticClass:"pure-u-1-3 desktop-only"},[_c('router-link',{attrs:{"to":_vm.indexRoute}},[_c('a',{staticClass:"logo",attrs:{"aria-label":"Ferrit Electric"}},[_c('icon',{attrs:{"width":"213","height":"80","name":"logo-electric"}})],1)])],1),_c('div',{staticClass:"pure-u-1 mobile-only",class:_vm.isMenuOpened ? 'fixed' : 'relative'},[_c('router-link',{attrs:{"to":_vm.indexRoute}},[_c('a',{staticClass:"logo",attrs:{"aria-label":"Ferrit Electric"}},[_c('icon',{attrs:{"width":"213","height":"80","name":"logo-electric"}})],1)]),_c('div',{staticClass:"spacer"}),_c('language-changer-simple'),_c('div',{staticClass:"burger",on:{"click":_vm.toggleMobileMenu}},[(!_vm.isMenuOpened)?_c('font-awesome-icon',{attrs:{"icon":"bars"}}):_vm._e(),(_vm.isMenuOpened)?_c('font-awesome-icon',{attrs:{"icon":"chevron-up"}}):_vm._e()],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMenuOpened),expression:"isMenuOpened"}],staticClass:"pure-u-1 mobile-only mobile-menu"},[_c('nav',[_c('div',{staticClass:"links"},_vm._l((_vm.mobileRoutes),function(route,key){return _c('router-link',{key:'r' + key,attrs:{"to":route.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('div',{staticClass:"item",class:[
              isExactActive && 'selected',
              route.isStandalone ? 'standalone' : 'indent' ]},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t(route.name)))])])]}}],null,true)})}),1)])]),_c('div',{staticClass:"desktop-only",class:("pure-u-" + (_vm.showLanguageChanger ? '7-12' : '2-3'))},[_c('nav',[_c('div',{staticClass:"links"},_vm._l((Object.keys(_vm.routes)),function(val,key){return _c('router-link',{key:'r' + key,attrs:{"to":_vm.routes[val]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isExactActive = ref.isExactActive;
return [_c('div',{staticClass:"item",class:[isExactActive && 'selected']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t(val)))])])]}}],null,true)})}),1)])]),(_vm.showLanguageChanger)?_c('div',{staticClass:"pure-u-1-12 desktop-only nav-cell"},[_c('language-changer')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }