
















import mixins from 'vue-typed-mixins'
import { LangMixin } from '@/mixins/lang.mixin'
import ArticleItem from '@/components/ArticleItem.vue'
import { ArticleModel } from '@/typings/article.typings'
import { Pages } from '@/typings/translations.typings'
import { Language } from '@/typings/translations.typings'
import { TitleMixin } from '@/mixins/title.mixin'

export default mixins(TitleMixin, LangMixin).extend({
  name: 'News',

  htmlTitle: Pages.news,

  components: {
    ArticleItem,
  },

  computed: {
    // FIXME (SEO)
    articles(): Array<ArticleModel> {
      return this.$store.state.articles
    },
  },

  watch: {
    lang: {
      immediate: true,
      handler(lang: Language) {
        this.$store.dispatch('getArticles', lang)
      },
    },
  },
})
