
























































import mixins from 'vue-typed-mixins'
import Bar from '@/components/Bar.vue'
import TextComponent from '@/components/TextComponent.vue'
import Icon from '@/components/Icon.vue'
import InfoTile from '@/components/InfoTile.vue'
import BoxCarousel from '@/components/carousel/BoxCarousel.vue'
import LinkCarousel from '@/components/carousel/LinkCarousel.vue'
import CuriosityStack from '@/components/CuriosityStack.vue'
import LookingForBar from '@/components/LookingForBar.vue'
import { Pages } from '@/typings/translations.typings'
import { TitleMixin } from '@/mixins/title.mixin'
import GuidePost from '@/components/GuidePost.vue'

export default mixins(TitleMixin).extend({
  name: 'IndexView',

  htmlTitle: Pages.index,

  components: {
    Bar,
    TextComponent,
    Icon,
    InfoTile,
    BoxCarousel,
    LinkCarousel,
    GuidePost,
    LookingForBar,
  },
})
