import { Language, Locale } from '../../typings/translations.typings'

export const translations: Locale[Language.CS] = {
  htmlTitle: {
    intranet: 'Intranet (cs)',
    index: '',
    about: 'O Nás',
    products: 'Produkty',
    news: 'Novinky',
    curiosity: 'Zajímavosti',
    career: 'Kariéra',
    contacts: 'Kontakty',
    contactPoints: 'Zastoupení firmy',
    certificates: 'Certifikáty',
    servicing: 'Servis',
    reference: 'Reference',
  },
  loginModal: {
    title: 'Přihlášení do Ferrit Electric',
    emailPlaceholder: 'Emailová adresa',
    passwordPlaceholder: 'Heslo',
    forgotPasswordAction: 'Obnovení hesla',
    forgotPasswordInstructions:
      'Prosím zadejte emailovou adresu. Pošleme vám email s instrukcemi ke změně hesla.',
    lastLoginInstructions: 'Poslední přihlášení s účtem',
    notYourAccountAction: 'Nejste to vy?',
    successForgotPassword:
      'Právě jsme vám odeslali email s instrukcemi ke změně hesla.',
  },
  intranet: 'Intranet',
  ferritCareer: 'Kariéra ve Ferritu',
  reference: 'Reference',
  servicing: 'Servis',
  certificates: 'Certifikáty',
  contactPoints: 'Zastoupení firmy',
  branches: 'Pobočky',
  about: 'O nás',
  products: 'Produkty',
  news: 'Novinky',
  contacts: 'Kontakty',
  career: 'Kariéra',
  curiosity: 'Zajímavosti',
  lookingFor: 'Chcete se na našich produktech podílet?<br> Přidejte se k nám',
  openPositions: 'Volné pozice',
  playVideo: 'Přehrát video',
  quickSection: 'Rychlá sekce',
  hrVideoDescription: 'FERRIT HR 2018 dlouhá verze',
  whatDoWeDo: 'Vyrábíme průmyslovou elektroniku',
  ferritServices: '<h2>Produkty <brand /></h2>',
  productReference: 'Reference produktů',
  customerReference: 'Reference zákazníků',
  aboutTheCompany: 'O společnosti',
  worldOfFerrit: '<h2>Co se děje ve <brand /></h2>',
  error404: 'Chyba 404',
  userWidget: {
    user: 'Uživatel',
    signout: 'Odhlásit se',
  },
  worldMapStack: {
    title: `<span>Naše výrobky se denně uplatňují v
      <div class="red">Evropě, Asii, Africe, Severní a Jižní Americe</div>
      při práci v</span>`,
    description: [
      ['Uhelných podzemních i povrchových dolech', 'Rudných dolech'],
      ['Diamantových dolech', 'Solných dolech'],
    ],
  },
  page: {
    index: {
      description: `<span></span>`,
      services: [
        {
          icon: 'fax',
          title: 'Vývoj a výroba',
          description:
            'Zajišťujeme pro zákazníky vývoj a výrobu řídicích systémů pro stroje a zářízení. Dodáváme také jednotlivé komponenty.',
        },
        {
          icon: 'people-carry',
          title: 'Servis',
          description:
            'Veškeré seřízení a nastavení technických parametrů můžete nechat na nás.',
        },
        {
          icon: 'file-signature',
          title: 'Certifikace',
          description:
            'Máme bezpečnostní certifikáty pro práci v prostorách plynujících dolů s nebezpečím výbuchu metanu či uhleného prachu.',
        },
      ],
      boxCarousel: [
        {
          image: 'index.jpg',
          title: 'Název produktu',
          description:
            '<p>Součástí závěsné jednokolejové dráhy jsou různé manipulační a doplňující prostředky, které usnadňují a urychlují pomocné činnosti.. </p>',
        },
        {
          image: 'ferrit_electric_assembly.jpg',
          title: 'Nadpis2',
          description: '<p>Text2</p>',
        },
      ],
      linkCarousel: [
        [
          {
            href: 'http://cosik.cz/reference1',
            title: 'Název reference 1',
          },
          {
            href: 'http://cosik.cz/reference2',
            title: 'Název reference 2',
          },
          {
            href: 'http://cosik.cz/reference3',
            title: 'Název reference 3',
          },
        ],
        [
          {
            href: 'http://cosik.cz/reference4',
            title: 'Název reference 4',
          },
          {
            href: 'http://cosik.cz/reference5',
            title: 'Název reference 5',
          },
          {
            href: 'http://cosik.cz/reference6',
            title: 'Název reference 6',
          },
        ],
      ],
      aboutTheCompany:
        '<p class="global-line-height">Společnost <brand/> je dceřinou společností <brand :exclude-electric="true" /> založenou s cílem vyvíjet a implementovat moderní elektronická mikroprocesorová řešení pro řízení a zabezpečení jak podzemních tak i pozemních důlních strojů určených i pro prostředí s nebezpečím výbuchu.</p>',
    },
    about: {
      h2: "<span>Co je charakteristické pro řešení <brand /></span>",
      description: `
        <span>
          Ve společnosti <brand/> navrhujeme a vyrábíme inovativní mikroprocesorové řídicí a ovládací systémy pro bezpečnou a automatizovanou obsluhu a používání
        </span>
      `,
      advantages: {
        1: '<p>Přímé, konstrukcí dané, určení pro náročné důlní prostředí s ohledem na vlhkost, plyny, teplotu a otřesy</p>',
        2: '<p>Využití unikátní a jedinečné znalosti konstrukce zařízení pro specifická důlní prostředí</p>',
        3: '<p>Špičková bezpečnost daná využitím rozsáhlých zkušeností z vývoje a využití našich zařízení v důlním provozu</p>',
        4: '<p>Vysoká mechanická a klimatická odolnost</p>',
        5: '<p>Testování každého dodaného zařízení na speciálním polygonu pro ověření jeho správnosti a bezpečnosti</p>',
        6: '<p>Společný vývoj strojů a sdílení know-how s nejvýznamnějším výrobcem důlních kolových strojů <brand :exclude-electric="true" /></p>',
        7: '<p>Certifikáty pro důlní prostředí s nebezpečím výbuchu metanu a uhleného prachu</p>',
      },
    },
    curiosity: {
      description: `<h2 class="red">Víte, že...</h2>`,
    },
    career: {
      description: `
        <span>
          Kontaktujte nás (ideálně zašlete životopis se svým kontaktem) na <a class="red" href="mailto:kariera@ferrit-electric.cz">kariera@ferrit-electric.cz</a> ozveme se Vám obratem.

        </span>
      `,
      h2: `<span>Volné pozice <brand/></span>`,
    },
    products: {
      description: '<span>Výrobky a služby <brand /></span>',
      products: {
        left: [
          'Zákaznický vývoj a výroba elektronických řídících systémů pro stroje a zařízení',
          'Centrální řídící jednotky strojů',
          'Displejové a zobrazovací jednotky, pulty řidiče',
          'Akumulátorové jednotky',
          'Zdroje, napájecí stanice, nabíjecí stanice',
          'Důlní čerpací stanice',
          'Čidla, senzory, loggery',
        ],
        right: [
          'Světla, houkačky, majáky',
          'Důlní alternátory',
          'Ochrany a bariéry',
          'Kamerové systémy pro vzdálenou obsluhu strojů',
          'Radiová dálková ovládání strojů i s přenosem obrazu',
          'Přístupové a ovládací jednotky a klíče',
          'Servisní a náhradní díly pro důlní stroje',
        ],
      },
    },
    certificates: {
      description: `
      <span>
        <h3>Abychom se splnili požadavky zákazníků v bezpečně a klimaticky náročném prostředí, certifikujeme každý produkt, určený pro prostředí s nebezpečím výbuchu, podle platných norem u zkušebních certifikačních úřadů.</h3>
        
        <br/><p/>
        <span class="bold">
          Většinu produktů společností <brand :exclude-electric="true" /> a <brand/> je možno používat v prostorách plynujících dolů s nebezpečnými atmosférickými podmínkami 2 dle ČSN EN 1127-2 (EN 1127-2) a v prostorách  s nebezpečím výbuchů metanu a uhelného prachu, zařazených podle § 232 odst. 1 písm. b) a § 233 odst. 1 písm. b) vyhlášky ČBÚ č. 22/1989 Sb., včetně dolů s nebezpečím průtrží hornin  a plynů a dolů zařazených mezi nebezpečné důlními otřesy, s výjimkou prostor s nebezpečím výbuchu metanu zařazených podle § 242 odst. 3 téže vyhlášky. Svým provedením je většina produktů společností <brand :exclude-electric="true" /> a <brand/> zařazena do skupiny I, kategorie M2 dle Nařízení vlády č. 116/2016 Sb. (směrnice Evropského parlamentu a Rady EU č. 2014/34/EU).
        </span>    
      </span>`,
    },
    servicing: {
      description: `
        <span>
        <h2>Pro naše zákazníky poskytujeme:</h2>
        <p/>
        <ul>
          <li>Služby servisu, tj. záruční a pozáruční opravy</li>
          <li>Rozvoj a zákaznické úpravy produktů na zakázku</li>
          <li>Technologickou podporu</li>
          <li>Zákaznický vývoj produktů, spojených s hornictvím</li>
        </ul>
         <p/>
        
        
        </span>`,
    },
    reference: {
      description: `<span></span>`,
    },
  },

  icons: {
    lifting: 'Důlních zvedacích a transportních strojů',
    suspension: 'Důlních závěsných lokomotiv',
    lacustrian: 'Důlních kolejových lokomotiv',
    rails: 'Důlních kolejových strojů',
    electric: 'Důlní technologie pro obsluhu strojů',
  },
  bar: {
    reference: `<div>Naši zákazníci nám určují směr naší práce, směřujeme s nimi ke společnému cíli.</div>`,
    servicing: `<div>Péče o zákazníka nekončí prodejem.</div>`,
    certificates: `<div>Bezpečnost klademe v důlních provozech na první místo.</div>`,
    index: `
      <div>
        Společnost <brand/> vyrábí inovativní mikroprocesorové řídící  systémy pro důlní závěsné a
        kolejové lokomotivy. Dále navrhujeme technologii pro obsluhu kolových, zvedacích či transportních strojů.
      </div>
    `,
    career: `<div>Společnost <brand/> hledá do svého firemního kolektivu šikovné, motivované lidi, kteří se rádi učí novým věcem. 
      <p/>
      Podívejte se také na možnosti pracovního uplatnění v naší mateřské společnosti <brand :exclude-electric="true" />.
      </div>`,

    about: `
      <span>
      Společnost <brand /> je dceřinou společností <brand :exclude-electric="true" />, založenou s cílem vyvíjet a implementovat moderní elektronická mikroprocesorová řešení pro řízení a zabezpečení jak podzemních tak i pozemních důlních strojů určených i pro prostředí  s nebezpečím výbuchu. 
      </span>
    `,
    news: `
      <div>Stálé inovace představují jeden z pilířů naší práce. Ale neinovujeme jen naše výrobky, ale také svůj přístup k našim zaměstnancům, dodavatelům a životnímu prostředí.</div>
    `,
    contacts: `<div>Společnost <brand/> navrhuje a vyrábí inovativní mikroprocesorové řídící a ovládací systémy pro bezpečnou a automatizovanou obsluhu a používání.</div>`,
    products: `<div>Produkty dodávané společností <brand/> jsou vysoce technicky vyspělé produkty pro řízení a zabezpečení práce důlních přepravních a těžebních strojů. Jejich vývoji a produkci je věnována mimořádná péče s cílem spojit vysokou kvalitu výrobků s dobrým servisem a zajímavou cenou.</div>`,
    positions: `<div></div>`,
    curiosity: `<div>Náš svět je plný nových, zajímavých technologií a aktivit.</div>`,
    contactPoints: `<div>Produkty společnosti <brand/> jsou nabízeny spolu se stroji společnost <brand :exclude-electric="true" /> téměř na všech světadílech. </div>`,
    error404: `<div>Požadovaná stránka nenalezena.</div>`,
  },
  curiosities: {
    nontechnical: [
      '<p><brand/> zaměstnává unikátní kolektiv více jak 20 vývojářů, elektroniků a montérů elektronických komponent pro důlní zařízení. Kolektiv je rozčleněn do dvou oddělení – oddělení Vývoje a oddělení Výroby. Příjemné pracovní prostředí, rodinná atmosféra a prostředí vzájemné důvěry nám dává radost z vzájemného setkání při práci s nejmodernějšími technologiemi. Proti současnému nedostatku pracovních sil bojujeme přátelským prostředím, zajímavou prací, kvalitním vybavením a v neposlední řadě vzájemným sdílením a rozšiřováním znalostí.</p>',
      '<p>Naše elektronická zařízení slouží v severní Americe, Jižní Americe, Africe, Asii, Evropě a Austrálii. Ano, všude tam, kde se těží, přinášejí naše stroje vysoký užitek daný důrazem na výkon a kvalitu. Náš nejvzdálenější stroj slouží v daleké Chile.</p>',
      '<p>Nejsevernějším místem, kde jezdí naše stroje, je Norilsk v Ruské federaci, ležící až za polárním kruhem. V Norilsku  naše stoje těží kovy (hlavně nikl) a uhlí. Těžební průmysl však zde představuje velkou zátěž pro životní prostředí, Norilsk patří mezi nejznečištěnější města na světě, zima je tady krutá a dlouhá. 29. prosince 2015 zde byla naměřena teplota -64 °C. I v takovém prostředí naše stroje uspěly.</p>',
      `<p>Naše stroje dělají nebezpečné bezpečným. Uvědomujeme si, že tam kde budou stroje pracovat je důlní prostředí, voda, prach, plyn, koroze a výbušný plyn. Pracují zde lidé, pro něž je zajištění a udržení bezpečnosti nejvyšší prioritou. Zodpovědným dodržováním všech bezpečnostních předpisů, zajištěním bezpečnosti strojů a příslušenství chráníme havířům po celém světě životy a zdraví.

Kvalitní a včasný servis našich strojů je činností, na které jsme vyrostli. Zákazníci očekávají, že je v jejich problémech nenecháme a jejich problémy se stanou problémy našimi. Každý stroj má diagnostickou část, ve které jsou uložená data o provozu a plnění technických podmínek práce strojem. Jsme tím schopni dokladovat bezvadnou činnost stroje a trvale a otevřeně prokazovat jejich kvalitu a přínos a zároveň se pokusit předcházet budoucím poruchám a účinně se jim preventivně bránit. 

Největší stroje, řízené a zabezpečené naší technologií jsou důlní nakladače DNK10F s délkou 10 metrů, šířkou 2,5 metrů a váhou 30 tun. DNK10F naloží najednou do lopaty až 10 000kg. Nejmenším strojem je DMZ50-RC, důlní závěsná lokomotiva s dálkovým ovládáním. Lokomotiva je tak kompaktní, že strojník, který ji ovládá už nemá kabinu, ale jde vedle stroje a ovládá ji dálkovým ovládáním.
</p>`,
      '<p>Naše práce nás baví. Kde jinde si můžete vyzkoušet ovládat dálkovým ovládáním náš největší „angličák“ - důlní nakladač stroj DNK 10 s váhou 30 tun, který naloží na jeden záběr lopaty 10 tun materiálu?</p>',
    ],
    technical: [
      '<p>Oblast elektroniky, ve které se naši vývojáři pohybují je TOP oblastí. Zpracováváme celkové řešení od návrhu, simulace výrobku, přes návrh desky plošných spojů, výrobu prototypu a ověření výrobku v reálném provozu.</p>',
      '<p>Naše elektronické komponenty a přístroje vyvíjíme na moderních počítačových softwarových  nástrojích, které umožňují navržené zařízení virtualizovat, vizualizovat a simulovat tak jeho provoz a změřit vlastnosti ještě před zapájením jediné součástky. To značně urychluje vývoj a předchází chybám.</p>',
      '<p>Existuje zásadní rozdíl mezi realtime technologiemi, které používáme ve svých výrobcích a běžnými PC technologiemi.  Vestavěný systém (zabudovaný systém, embedded system) je jednoúčelový systém, ve kterém je řídicí počítač zcela zabudován do zařízení, které ovládá. Na rozdíl od univerzálních počítačů, jako jsou osobní počítače, jsou zabudované počítače většinou jednoúčelové, určené pro předem definované činnosti a pro rychlou práci v reálném čase. Zde hlavně záleží na jeho rychlé reakci na vstupní parametry, vždyť přece řídí reálný stroj v reálném prostředí, kde se nic nesmí zaseknout nebo restartovat.</p>',
      '<p>Naše práce vývojová je stále více řízena projektově. Investujeme do školení a připravujeme projektové certifikace pro naše vývojáře. Výsledkem je kvalitní a efektivní práce bez zbytečných stresů a spokojenost zákazníka s úspěšným projektem. Své znalosti a zkušenosti sdílíme společně v týmu. Pokud se nám něco nepodaří, umíme se podívat zpět a z chyby se poučit.</p>',
      '<p>Vzdělávání v naší společnosti je nutností. Zahájený program vzdělávání obsahuje Anglický a Ruský jazyk, probíhají odborná školení a také školení měkkých dovedností. Abychom byli ve styku se zákazníky, naši pracovníci se zúčastňují zahraničních konferencí a výstav.</p>',
      '<p>Stroje důkladně testujeme spolu s pracovníky FERRITu na firemním polygonu. Možnost jeho využití nám přináší schopnost důkladně otestovat každý vyvíjený komponent v reálném použití, vidět jeho skutečné fyzikální a technické vlastnosti a ověřit si jeho kvalitu a funkčnost. V neposlední řadě nám polygon umožňuje si při práci hrát!</p>',
      '<p>Rozvíjíme projekt elektrifikace našich strojů novými Lithiovými bateriemi. Zde je část naší technické a produktové budoucnosti, i zde chceme být technologickým leaderem.</p>',
      '<p>Pracujeme na zapojení našich strojů do systému autonomního řízení, kdy vybrané stroje budou pracovat samostatně, autonomně. Budeme tak zabezpečovat provoz tam, kde lidé být nemohou nebo kde se nevyplatí. Autonomní stroje, takové důlní Tesly, jsou horkou novinkou a my jsme u toho!</p>',
      '<p>Připojili jsme se k mateřské společnosti <brand :exclude-electric="true" /> v projektu zlepšování a dobrých nápadů, kde je možno uplatnit své přínosné nápady a vydělat si další odměnu.</p>',
      '<p>Certifikace našich výrobků do důlního provozu je jednou z nejsložitějších činností a začíná už při návrhu a vývoji výrobku. Certifikací a srovnáním s normami ověříme vysokou bezpečnost našich výrobků.</p>',
      '<p>Některé části našich výrobků tiskneme na 3D tiskárnách. V budoucnosti zvažujeme investice do těchto technologií pro jejich lepší využití při efektivní kusové nebo malosériové výrobě.</p>',
    ],
  },
  contactsInfo: {
    ico: 'IČO',
    phone: 'Tel.',
    mobilePhone: 'Mob.',
    fax: 'Fax',
    extract: 'Výpis z <a href="https://or.justice.cz/ias/ui/rejstrik-firma.vysledky?subjektId=345275&typ=PLATNY" class="red">Obchodního rejstříku</a>',
    addressJobSeekers: 'Adresa pro uchazeče o práci',
    orders: 'Objednávky',
    management: 'Vedení společnosti',
    ceo: 'Výkonný ředitel',
    contactPerson: "Kontaktní osoba",
  },
  contactPointLocation: {
    czechRepublic: 'Česká republika',
    slovakia: 'Slovenská republika',
    poland: 'Polsko',
    russia: 'Ruská federace',
    kazakhstan: 'Kazachstán',
    ukraine: 'Ukrajina',
    bosnia: 'Bosna a Hercegovina',
    vietnam: 'Vietnam',
    turkey: 'Turecko',
    india: 'Indie',
    africa: 'Subsaharská Afrika',
    columbia: 'Kolumbie',
    china: 'Čína',
    peruBoliviaEcuador: 'Peru, Bolívie, Ekvádor',
  },
  genericLoginError:
    'Během přihlašování nastala chyba. Zkuste akci zopakovat.',
}
