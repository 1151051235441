import Vue from 'vue'

const tablet = 960
const mobile = 480

export const DeviceDetectMixin = Vue.extend({
  data() {
    return {
      deviceType: '',
    }
  },
  computed: {
    isMobile(): boolean {
      return this.deviceType === 'mobile'
    },
  },

  mounted() {
    const width = this.$el.clientWidth
    if (width <= mobile) {
      this.deviceType = 'mobile'
    } else if (width > mobile && width <= tablet) {
      this.deviceType = 'tablet'
    } else {
      this.deviceType = 'desktop'
    }
  },
})
