<script>
import Vue from 'vue'
import Brand from './Brand.vue'

export default Vue.extend({
  props: {
    description: {
      type: String,
      required: true,
    },
  },

  render(createElement) {
    return createElement({
      template: this.description,
      components: { Brand },
    })
  },
})
</script>
