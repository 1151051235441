





















































import Vue from 'vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { secureAjax } from '@/utils/axios.helpers'
import { Scope } from '@/utils/auth0.consts'
import { adminLanguages } from '@/utils/lang.consts'
import { runningInProductionMode } from '@/utils/mode'
import { Language } from '@/typings/translations.typings'

interface glyph {
  text: string
  value: string
}

interface CareerResponse {
  status: string
  career: {
    id: number
    title: string
  }
}

export default Vue.extend({
  name: 'AddBulletinForm',

  components: {
    ValidationObserver,
    ValidationProvider,
    FontAwesomeIcon,
  },

  data(): any {
    return {
      title: '',
      email: '',
      description: '',
      lang: Language.CS,
      languages: adminLanguages,
    }
  },

  created() {
    this.$store.dispatch('getGallery')
  },

  computed: {
    gallery() {
      return this.$store.state.gallery
        .map((it: any) => {
          const splitted = it.split('/')
          return splitted[splitted.length - 1]
        })
        .map((it: any) => ({ value: it, text: it }))
    },

    base(): string {
      return runningInProductionMode ? '' : 'http://localhost:3300'
    },
  },

  methods: {
    sendForm() {
      const data = {
        title: this.title,
        description: this.description,
        lang: this.lang,
      }

      secureAjax(
        {
          method: 'POST',
          url: '/bulletin',
          data,
        },
        Scope.MANAGE_ADMIN
      )
        .then((response: any) => {
          this.title = ''
          this.image = ''
          this.description = ''

          alert(`Interní zpráva '${response.data.bulletin.title}' publikována`)
        })
        .catch(() => {
          alert('Při zápisu nastala chyba')
        })
    },
  },
})
