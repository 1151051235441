import { render, staticRenderFns } from "./InfoTile.vue?vue&type=template&id=5d129e7f&scoped=true&"
import script from "./InfoTile.vue?vue&type=script&lang=ts&"
export * from "./InfoTile.vue?vue&type=script&lang=ts&"
import style0 from "./InfoTile.vue?vue&type=style&index=0&id=5d129e7f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d129e7f",
  null
  
)

export default component.exports