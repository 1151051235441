














import Vue from 'vue'
import { mapActions } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { User } from '@/typings/store.typings'

export default Vue.extend({
  name: 'RouteGuard',

  components: { FontAwesomeIcon },

  props: {
    scope: {
      type: String,
      required: true,
    },
  },

  computed: {
    user(): User {
      return this.$store.state.user
    },

    isAuthenticated(): boolean {
      return this.user.isAuthenticated
    },

    isLoading(): boolean {
      return this.user.isLoading
    },
  },

  methods: {
    ...mapActions(['getUser']),
  },

  created() {
    // Disable SSR for protected routes
    if (typeof window !== 'undefined' && !this.user.isAuthenticated) {
      this.getUser({
        scope: this.scope,
        redirectPath: this.$route.matched.find(route => route.meta.redirectPath)
          .meta.redirectPath,
      })
    }
  },
})
