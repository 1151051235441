var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content pure-g"},[_c('div',{staticClass:"pure-u-1 pure-u-md-6-24"},[_c('ul',[_c('li',[_c('brand',{attrs:{"force-latin":true}})],1),_c('li',[_vm._v("Na Zbytkách 41")]),_c('li',[_vm._v("739 01 Staré Město")]),_c('li',[_vm._v("Czech Republic")])])]),_c('div',{staticClass:"pure-u-6-24 desktop-only"},[_c('ul',{staticClass:"bold"},_vm._l((_vm.column(0)),function(val){return _c('router-link',{key:'r' + val.name,attrs:{"to":val.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"item",class:[isExactActive && 'selected']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t(val.name)))])])]}}],null,true)})}),1)]),_c('div',{staticClass:"pure-u-6-24 desktop-only"},[_c('ul',{staticClass:"bold"},_vm._l((_vm.column(1)),function(val){return _c('router-link',{key:'r' + val.name,attrs:{"to":val.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"item",class:[isExactActive && 'selected']},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t(val.name)))])])]}}],null,true)})}),1)]),_c('div',{staticClass:"pure-u-1-6 flex-col desktop-only-flex"},[_c('div',{staticClass:"spacer"}),_c('brand',{staticClass:"pull-right",attrs:{"prefix":"©","suffix":"2019","force-latin":true}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }