

































import mixins from 'vue-typed-mixins'
import TextComponent from '@/components/TextComponent.vue'
import CareerOfferItem from '@/components/CareerOfferItem.vue'
import CareerOfferDetail from '@/components/CareerOfferDetail.vue'
import { CareerModel } from '@/typings/career.typings'
import { Pages } from '@/typings/translations.typings'
import { Language } from '@/typings/translations.typings'
import { PageMixin } from '@/mixins/page.mixin'
import { LangMixin } from '@/mixins/lang.mixin'
import { TitleMixin } from '@/mixins/title.mixin'

interface CareerData {
  currentId: number | null
}

export default mixins(TitleMixin, PageMixin, LangMixin).extend({
  name: 'Career',

  htmlTitle: Pages.career,

  components: {
    TextComponent,
    CareerOfferItem,
    CareerOfferDetail,
  },

  data(): CareerData {
    return {
      currentId: null,
    }
  },

  computed: {
    selectedDetail(): CareerModel | {} {
      // Print out detail that is selected by user
      if (this.currentId !== null) {
        return this.careers.length
          ? this.careers.find(item => item.id === this.currentId)
          : {}
      } else {
        return (
          this.careers.find(item => item.is_pre_selected) || {
            title: '',
            description: '',
          }
        )
      }
    },

    // FIXME async computed (SEO)
    careers(): Array<CareerModel> {
      return this.$store.state.careers
    },
  },

  watch: {
    lang: {
      immediate: true,
      handler(lang: Language) {
        this.$store.dispatch('getCareers', lang).then(() => {
          if (this.careers.length === 0) {
            return;
          }

          const currentCareer = this.careers.find(it => it.is_pre_selected)
          const currentId = currentCareer ? currentCareer.id : this.careers[0].id;
          this.setCurrentId(Number(currentId))
        })
      },
    },
  },

  methods: {
    setCurrentId(id: number) {
      this.currentId = id
    },
  },
})
