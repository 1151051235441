import Vue from 'vue'
import { Language } from '@/typings/translations.typings'

export const LangMixin = Vue.extend({
  computed: {
    lang(): string {
      return this.$route.params.lang || Language.CS
    },
  },
})
