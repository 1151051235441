import Vue from 'vue'

export const PrettyDate = Vue.extend({
  methods: {
    prettyDate(dateString: string) {
      const date: Date = new Date(dateString)
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
    },
  },
})
