











import Vue from 'vue'

export default Vue.extend({
  name: 'UserWidget',

  computed: {
    name(): string {
      return this.$store.state.user.metadata.name
    },
  },
})
