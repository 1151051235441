




























import Vue from 'vue'

interface AdminNavigationData {
  routes: Array<{
    name: string
    title: string
    isDivided?: boolean
  }>
}

export default Vue.extend({
  name: 'AdminNavigation',

  data(): AdminNavigationData {
    return {
      routes: [
        {
          name: 'admin-add-article',
          title: 'Přidat novinku',
          isDivided: true,
        },
        {
          name: 'admin-add-career',
          title: 'Přidat kariéru',
        },
        {
          name: 'admin-add-bulletin',
          title: 'Přidat interní zprávu',
        },
        {
          name: 'admin-manage-careers',
          title: 'Správa kariér',
          isDivided: true,
        },
        {
          name: 'admin-manage-bulletins',
          title: 'Správa interních zpráv',
        },
        {
          name: 'admin-manage-articles',
          title: 'Správa novinek',
        },
        {
          name: 'admin-manage-gallery',
          title: 'Správa galerie',
        },
        {
          name: 'admin-manage-documents',
          title: 'Správa dokumentů',
        },
      ],
    }
  },
})
