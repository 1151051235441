













































































import Vue from 'vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { secureAjax } from '@/utils/axios.helpers'
import { Scope } from '@/utils/auth0.consts'
import { adminLanguages } from '@/utils/lang.consts'
import { Language } from '@/typings/translations.typings'

interface glyph {
  text: string
  value: string
}

interface AddCareerFormData {
  title: string
  icon: string
  email: string
  isPreSelected: boolean
  description: string
  lang: string
  languages: Array<object>
  icons: Array<glyph>
}

interface CareerResponse {
  status: string
  career: {
    id: number
    title: string
  }
}

export default Vue.extend({
  name: 'AddCareerForm',

  components: {
    ValidationObserver,
    ValidationProvider,
    FontAwesomeIcon,
  },

  data(): AddCareerFormData {
    return {
      title: '',
      icon: '',
      email: '',
      isPreSelected: false,
      description: '',
      lang: Language.CS,
      languages: adminLanguages,
      icons: [
        { value: 'cog', text: 'Ozubené kolo' },
        { value: 'bolt', text: 'Blesk' },
        { value: 'wrench', text: 'Klíč' },
      ],
    }
  },

  methods: {
    sendForm() {
      const data = {
        title: this.title,
        icon: this.icon,
        isPreSelected: this.isPreSelected,
        description: this.description,
        lang: this.lang,
      }

      secureAjax(
        {
          method: 'POST',
          url: '/career',
          data,
        },
        Scope.MANAGE_ADMIN
      )
        .then((response: any) => {
          this.title = ''
          this.icon = ''
          this.description = ''

          alert(`Kariéra '${response.data.career.title}' publikována`)
        })
        .catch(() => {
          alert('Při zápisu nastala chyba')
        })
    },
  },
})
