var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bar",class:{
    'border-bottom': _vm.hasBorder,
    'pure-g': !_vm.isVertical,
    swap: _vm.swapColumns,
    white: _vm.whiteBackground,
  },style:({
    height: _vm.hasRectangle ? (_vm.height + "px") : undefined,
    minHeight: (_vm.height + "px"),
  })},[_c('div',{staticClass:"bar-item",class:[!_vm.isVertical && ("pure-u-1 pure-u-md-" + (_vm.grid[0]))]},[_c('div',{staticClass:"image",class:{ contain: _vm.hasContainBackgorund },style:({
        backgroundImage: 'url(' + require(("@/assets/" + _vm.image)) + ')',
        minHeight: _vm.isVertical ? undefined : (_vm.height + "px"),
      })})]),_c('div',{staticClass:"bar-item",class:[!_vm.isVertical && ("pure-u-1 pure-u-md-" + (_vm.grid[1]))],style:({ minHeight: _vm.isVertical ? undefined : (_vm.height + "px") })},[_c('div',{staticClass:"rectangle-wrapper",class:[_vm.whiteBackground && 'white', _vm.isOverlayActive && 'overlay-active']},[(_vm.hasDescriptionBox)?_c('div',{staticClass:"content",class:[
          _vm.isVertical && 'is-vertical',
          _vm.swapColumns && 'swap',
          _vm.title && 'with-description-box' ]},[(!_vm.isVertical && _vm.hasRectangle)?_c('div',{staticClass:"rectangle",class:{
            top: _vm.angleSide === 'top',
            swap: _vm.swapColumns,
            short: _vm.height < 350,
            white: _vm.whiteBackground,
          }}):_vm._e(),_c('description-box',{staticClass:"bar-description-box",attrs:{"title":_vm.title,"description":_vm.description,"has-triangle":false,"no-padding":!_vm.isVertical}})],1):_c('div',{staticClass:"content",class:[_vm.isVertical && 'is-vertical', _vm.swapColumns && 'swap']},[_c(_vm.headingElement,{tag:"component",domProps:{"innerHTML":_vm._s(_vm.title)}}),_c('div',{staticClass:"rectangle",class:{
            top: _vm.angleSide === 'top',
            swap: _vm.swapColumns,
            short: _vm.height < 350,
            white: _vm.whiteBackground,
          }}),_c('component-loader',{staticClass:"description",attrs:{"description":_vm.description}}),_c('div',{staticClass:"spacer"}),(_vm.video.url)?_c('silentbox-single',{ref:"video",attrs:{"src":_vm.video.url,"description":_vm.video.description}},[_c('btn',{staticClass:"pull-right",attrs:{"title":_vm.$t('playVideo'),"icon":_vm.video.icon}})],1):_vm._e(),(_vm.link.path)?_c('div',{staticClass:"button-link"},[_c('a',{attrs:{"href":_vm.link.path}},[_c('btn',{staticClass:"pull-right",attrs:{"title":_vm.link.title,"icon":_vm.link.icon}})],1)]):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }