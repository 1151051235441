














import { Location } from 'vue-router'
import mixins from 'vue-typed-mixins'
import { generateLocationWithRouteAlias } from '@/locale/routes'
import { LangMixin } from '@/mixins/lang.mixin'
import { PrettyLanguage, LanguageFlags, Language } from '@/typings/translations.typings'

export default mixins(LangMixin).extend({
  name: 'LanguageChangerSimple',

  data() {
    return {
      langInput: undefined,
    }
  },

  computed: {
    routeName(): string {
      return this.$route.name || ''
    },

    languages() {
      return PrettyLanguage
    },

    currentLanguage(): string {
      return LanguageFlags[(this.lang as Language)]
    },
  },

  watch: {
    lang: {
      immediate: true,
      handler() {
        this.langInput = this.lang
      },
    },
  },

  methods: {
    createLangParam(language: string): Location {
      const lang = <any>language
      const routeName = <any>this.routeName

      return generateLocationWithRouteAlias(routeName, lang)
    },

    changeLanguage(key: string) {
      this.$i18n.locale = key
      this.$router.push(this.createLangParam(key))
    },
  },
})
