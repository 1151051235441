






import Vue from 'vue'
import AddCareerForm from '@/components/admin/AddCareerForm.vue'

export default Vue.extend({
  name: 'AdminAddCareer',

  components: { AddCareerForm },
})
