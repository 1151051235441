<template>
  <span
    v-html="svg"
    class="svg-holder"
    :style="{ width: svgWidth, height: svgHeight, display: 'block' }"
  ></span>
</template>

<script>
const get = name => import(`../svg/${name}.svg` /* webpackChunkName: "svg-" */)

import Vue from 'vue'

// Lets allow em and px
const sizeTest = /.*[px]|[em]/
const defaultSizeUnit = 'px'

export default Vue.extend({
  name: 'icon',
  props: {
    name: {
      type: String,
      required: true,
    },

    width: {
      type: String,
      default: '95px',
    },

    height: {
      type: String,
      default: '95px',
    },
  },

  computed: {
    svgWidth() {
      return sizeTest.test(this.width)
        ? this.width
        : `${this.width}${defaultSizeUnit}`
    },

    svgHeight() {
      return sizeTest.test(this.height)
        ? this.height
        : `${this.height}${defaultSizeUnit}`
    },
  },

  asyncComputed: {
    svg: {
      get() {
        return new Promise((resolve, reject) => {
          get(this.name)
            .then(file => {
              resolve(file.default)
            })
            .catch(error => {
              console.warn(`Unable to load svg image ${this.name}`)
              reject(error)
            })
        })
      },
      default: '<svg></svg>',
    },
  },
})
</script>
