




























import Vue from 'vue'
import ComponentLoader from './ComponentLoader.vue'
import DescriptionBox from './DescriptionBox.vue'

export default Vue.extend({
  name: 'TimeLineWrapper',

  props: {
    position: {
      type: [Array, String],
      required: true,
    },

    description: {
      type: [Array, String],
      required: true,
    },

    image: {
      type: String,
      required: true,
    },

    isDouble: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ComponentLoader,
    DescriptionBox,
  },
})
