







import Vue from 'vue'
import ComponentLoader from './ComponentLoader.vue'
import { md } from '@/utils/md-renderer'

export default Vue.extend({
  props: {
    description: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },
  },

  components: {
    ComponentLoader,
  },

  computed: {
    parsedMd(): string {
      return `
        <span class="flex-col">
          <h2 class="mobile-only">${this.title}</h2>
          ${md.render(this.description)}
        </span>
        `
    },
  },
})
