






import Vue from 'vue'
import AddArticleForm from '@/components/admin/AddArticleForm.vue'

export default Vue.extend({
  name: 'AdminAddCareer',

  components: { AddArticleForm },
})
