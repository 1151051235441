























































import mixins from 'vue-typed-mixins'
import { TranslateResult } from 'vue-i18n'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Brand from '@/components/Brand.vue'
import { Pages } from '@/typings/translations.typings'
import { TitleMixin } from '@/mixins/title.mixin'
import ContactPointDetail from '@/components/ContactPointDetail.vue'

interface Branch {
  id: number
  title: TranslateResult
  address: string | Array<string>
  contact: string | Array<string>
}

interface ContactPoints {
  selected: number
}

export default mixins(TitleMixin).extend({
  name: 'ContactPoints',

  htmlTitle: Pages.contactPoints,

  data(): ContactPoints {
    return {
      selected: 0,
    }
  },

  components: {
    Brand,
    FontAwesomeIcon,
    ContactPointDetail,
  },

  computed: {
    branches(): Array<Branch> {
      const commonTranslations = {
        phone: this.$t('contactsInfo.phone'),
        mobilePhone: this.$t('contactsInfo.mobilePhone'),
        fax: this.$t('contactsInfo.fax'),
        contactPerson: this.$t('contactsInfo.contactPerson'),
      }

      return [
        {
          id: 0,
          title: this.$t('contactPointLocation.czechRepublic'),
          address: `
              <p>Ferrit s.r.o</p>
              <p>Na Zbytkách 41</p>
              <p>739 01 Staré Město</p>
              <p>Czech Republic</p>
          `,
          contact: `
             <p>${commonTranslations.phone}: +420 558 411 605</p>
              <p>${commonTranslations.fax}: +420 558 411 620</p>
              <p>E-mail: <a href="mailto:ferrit@ferrit.cz" class="red">ferrit@ferrit.cz</a> </p>

          `,
        },
        {
          id: 1,
          title: this.$t('contactPointLocation.slovakia'),
          address: `
            <p>Ferrit Slovakia s.r.o.</p>
            <p>Košovská 309/18</p>
            <p>972 17 Kanianka</p>
            <p>Slovakia</p>
          `,
          contact: `
            <p>${commonTranslations.phone}: +421 465 420 235</p>
            <p>${commonTranslations.phone}: +421 465 420 236</p>
            <p>${commonTranslations.mobilePhone}: +421 910 916 969</p>
            <p>${commonTranslations.mobilePhone}: +421 903 271 200</p>
            <p>${commonTranslations.fax}: +421 465 401 138</p>
            <p>E-mail: <a href="mailto:ferrit@ferrit.sk" class="red" >ferrit@ferrit.sk</a></p>
          `,
        },
        {
          id: 2,
          title: this.$t('contactPointLocation.poland'),
          address: `
            <p>Ferrit Poland Sp. z o.o.</p>
            <p>Ul. Warowna 49</p>
            <p>43-200 Pszczyna </p>
            <p>Poland</p>
          `,
          contact: `
            <p>${commonTranslations.phone}: +048 604 254 094</p>
            <p>Serwis 24h: +420 777 704 835</p>
            <p>E-mail: <a href="mailto:poland@ferrit.cz" class="red">poland@ferrit.cz</a> </p>
          `,
        },

        {
          id: 3,
          title: this.$t('contactPointLocation.russia'),
          address: `
            <p>ООО «SIBTRANSSERVIS»</p>
            <p>Zorina str. 8-b</p>
            <p>Leninsk-Kuznetsky 652 502</p>
            <p>Russia</p>
          `,
          contact: `
            <p>${commonTranslations.phone}: +7 3845 653 131</p>
            <p>${commonTranslations.phone}: +7 3845 653 130</p>
            <p>${commonTranslations.fax} +7 3845 653 128A</p>
            <p>E-mail: <a href="mailto:sibtranss@mail.ru" class="red">sibtranss@mail.ru</a></p>
            <p>Web: <a href="www.sibtranss.ru" class="red" >www.sibtranss.ru</a></p>
          `,
        },
        {
          id: 4,
          title: this.$t('contactPointLocation.kazakhstan'),
          address: `
              <p> ТОО "Карферр"</p>
              <p> 100000, г. Караганда</p>
              <p> пр. Бухар-Жырау 57/1, офис 806</p>
              <p> Pеспублика Казахстан </p>
          `,
          contact: `
            <p>${commonTranslations.phone}/${commonTranslations.fax}: +7 7212 506 376</p>
            <p>E-mail: <a href="mailto:karferr@mail.ru" class="red">karferr@mail.ru</a></p>
          `,
        },

        {
          id: 5,
          title: this.$t('contactPointLocation.ukraine'),
          address: [
            `
            <p> IP „UKRTRANSSERVIS“ </p>
            <p> Ul. Grushevskogo Mikhaila, 4A </p>
            <p> 01001, Kyjev</p>
          `,
            `
            <p> ООО "ЮФЕРС"</p>
            <p> 08292, Киевская обл.,</p>
            <p> г. Буча, ул. Киево-Мироцкая, 170-В</p>
          `,
          ],
          contact: [
            `
                <p> ${commonTranslations.mobilePhone}: +38 050 425 3562</p>
                <p> ${commonTranslations.mobilePhone}: +38 050 175 7759</p>
                <p> E-mail: <a href="mailto:ip.ukrtransservis@gmail.com" class="red">ip.ukrtransservis@gmail.com</a></p>
              `,

            `
                <p> E-mail: <a href="mailto:ooo.ufers@gmail.com" class="red">ooo.ufers@gmail.com</a></p>
                <p> ${commonTranslations.mobilePhone}: +38 099 077 1103 </p>
              `,
          ],
        },
        {
          id: 6,
          title: this.$t('contactPointLocation.bosnia'),
          address: `
            <p> En - Union d.o.o.</p>
            <p> Mikelje Tešica 12</p>
            <p> 75000 Tuzla</p>
            <p> Bosnia and Herzegovina </p>
          `,
          contact: `
            <p> ${commonTranslations.phone}: +387 35 313 - 110</p>
            <p> ${commonTranslations.fax}: +387 35 313 - 120</p>
            <p> E-mail: <a href="mailto:en_union@bih.net.ba" class="red">en_union@bih.net.ba</a></p>
            <p> E-mail: <a href="mailto:ferrit@ferrit.cz" class="red">ferrit@ferrit.cz</a> </p>
          `,
        },
        {
          id: 7,
          title: this.$t('contactPointLocation.vietnam'),
          address: `
            <p> Ferrit s.r.o.</p>
            <p> Na Zbytkách 41</p>
            <p> 739 01 Staré Město</p>
            <p> Czech Republic </p>
          `,
          contact: `
            <p> ${commonTranslations.phone}: +420 558 411 605</p>
            <p> E-mail: <a href="mailto:ferrit@ferrit.cz" class="red">ferrit@ferrit.cz</a> </p>
          `,
        },
        {
          id: 8,
          title: this.$t('contactPointLocation.turkey'),
          address: `
            <p> FERRIT MAKINE ve MADENCİLİK A.Ş.</p>
            <p> Beştepe Mah. Nergiz SK. 7 A 33 </p>
            <p> Yenimahalle – Ankara</p>
            <p>Türkiye</p>
          `,
          contact: `
            <p> ${commonTranslations.phone}: +90 312 473 5762</p>
            <p> ${commonTranslations.fax}: +90 312 473 5736</p>
            <p> E-mail.: <a href="mailto:juraj.svorc@ferrit.cz" class="red">juraj.svorc@ferrit.cz</a> </p>
          `,
        },
        {
          id: 9,
          title: this.$t('contactPointLocation.india'),
          address: `
            <p> Ferrit s.r.o.</p>
            <p> Na Zbytkách 41</p>
            <p> 73901 Staré Město</p>
            <p> Czech Republic </p>
          `,
          contact: `
            <p> ${commonTranslations.phone}: +420 558 411 605</p>
            <p> E-mail.: <a href="mailto:ferrit@ferrit.cz" class="red">ferrit@ferrit.cz</a> </p>
          `,
        },
        {
          id: 10,
          title: this.$t('contactPointLocation.africa'),
          address: [
            `
            <p> Ferrit s.r.o.</p>
            <p> Na Zbytkách 41</p>
            <p> 739 01 Staré Město</p>
            <p> Czech Republic </p>
          `,
            `
            <p>${commonTranslations.contactPerson}:</p>
            <p>Michal Gamrot (FERRIT) </p>
            `,
          ],
          contact: [
            `
            <p> ${commonTranslations.phone}: +420 558 411 605</p>
            <p> ${commonTranslations.fax}: +420 558 411 620</p>
            <p> E-mail: <a href="mailto:ferrit@ferrit.cz" class="red">ferrit@ferrit.cz</a> </p>
          `,
            `
            <p> E-mail: <a href="mailto:gamrot@ferrit.cz" class="red">gamrot@ferrit.cz</a> </p>
          `,
          ],
        },
        {
          id: 11,
          title: this.$t('contactPointLocation.columbia'),
          address: [
            `
                <p> Ing. Andrés Felipe Rodríguez Puerta </p>
              `,
            `
                <p> ${commonTranslations.contactPerson}:</p>
                <p> Ing. Barbora Veličková (FERRIT) </p>
              `,
          ],
          contact: [
            `
              <p> ${commonTranslations.phone}: + 57 300 317 06 86</p>
              <p> E-mail: <a href="mailto:colombia@ferritamerica.com" class="red">colombia@ferritamerica.com</a> </p>
              `,
            `
              <p> E-mail: <a href="mailto:velickova@ferrit.cz" class="red">velickova@ferrit.cz</a> </p>
              `,
          ],
        },
        {
          id: 12,
          title: this.$t('contactPointLocation.china'),
          address: [
            `
            <p> FERRIT MINIG TRANSPORTATION</p>
            <p>EQUIPMENT (BEIJING) LTD.</p>
            <p>Floor 10, building 2, yard 1,</p>
            <p>dongsanhuan south road,</p>
            <p>chaoyang district,</p>
            <p>Beijing China. </p>
            <p> 100022</p>
          `,
            `
              <p> ${commonTranslations.contactPerson}:</p>
              <p> Bc. Hynek Kovář (FERRIT) </p>
            `,
          ],
          contact: [
            `
                <p> ${commonTranslations.phone}: +86 13 8119 30553</p>
                <p> ${commonTranslations.fax}: +86 10 5095 5938</p>
                <p> E-mail: <a href="mailto:ferrit_tracey@163.com" class="red">ferrit_tracey@163.com</a></p>
              `,
            `
                <p> E-mail: <a href="mailto:kovarh@ferrit.cz" class="red">kovarh@ferrit.cz</a> </p>
              `,
          ],
        },
        {
          id: 13,
          title: this.$t('contactPointLocation.peruBoliviaEcuador'),
          address: `
            <p> E-mail: <a href="mailto:peru@ferritamerica.com" class="red">peru@ferritamerica.com</a></p>
          `,
          contact: `
            <p> ${commonTranslations.phone}: +420 775797149 </p>

          `,
        },
      ]
    },

    currentBranch() {
      // @ts-ignore
      return this.branches[this.selected]
    },
  },

  methods: {
    branchColumn(index: number) {
      const from = index == 0 ? 0 : 7
      const to = index == 0 ? 7 : 14

      // @ts-ignore
      return Object.values(this.branches).splice(from, to)
    },
  },
})
