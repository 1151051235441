import Vue from 'vue'

export const PageMixin = Vue.extend({
  computed: {
    description(): string {
      return this.$t(
        `page.${this.$options.name.toLowerCase()}.description`
      ) as string
    },
  },
})
