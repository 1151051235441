









import mixins from 'vue-typed-mixins'
import { Pages } from '@/typings/translations.typings'
import { TitleMixin } from '@/mixins/title.mixin'
import { PageMixin } from '@/mixins/page.mixin'
import CuriosityStack from '@/components/CuriosityStack.vue'
import TextComponent from '@/components/TextComponent.vue'

export default mixins(TitleMixin, PageMixin).extend({
  name: 'Curiosity',

  htmlTitle: Pages.curiosity,

  components: {
    CuriosityStack,
    TextComponent,
  },
})
