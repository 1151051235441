




































import Vue from 'vue'
import ManageAction from '@/components/admin/ManageAction.vue'
import { secureAjax } from '@/utils/axios.helpers'
import { Scope } from '@/utils/auth0.consts'

export default Vue.extend({
  name: 'AdminManageOfficeDocuments',

  components: { ManageAction },

  data(): {
    documents: Array<string>
    documentMime: { [key in string]: string }
  } {
    return {
      documents: [],
      documentMime: {
        doc: 'application/msword',
        docx:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        odt: 'application/vnd.oasis.opendocument.text',
        ods: 'application/vnd.oasis.opendocument.spreadsheet',
        pdf: 'application/pdf',
      },
    }
  },

  methods: {
    async uploadDocument({
      currentTarget,
    }: {
      currentTarget: HTMLInputElement
    }) {
      const document = currentTarget.files[0]
      const formData = new FormData()
      formData.append('document', document)

      try {
        const { data } = await secureAjax(
          {
            method: 'POST',
            url: '/documents',
            data: formData,
          },
          Scope.MANAGE_ADMIN
        )

        this.invokeGetDocuments()
        alert(`Dokument '${data.image}' byl úspěšně nahrán`)
      } catch (e) {
        console.error(e)
        alert('Při nahrávání dokumentu nastala chyba')
      }
    },

    async deleteDocument(document: string) {
      try {
        await secureAjax(
          {
            method: 'DELETE',
            url: '/documents',
            data: { document },
          },
          Scope.MANAGE_ADMIN
        )

        this.invokeGetDocuments()
        alert(`Dokument '${document}' byl odstraněn`)
      } catch (e) {
        console.error(e)
        alert('Při odstraňování obrázku nastala chyba')
      }
    },

    async invokeGetDocuments() {
      try {
        const { data } = await secureAjax(
          {
            method: 'GET',
            url: '/documents',
          },
          Scope.MANAGE_ADMIN
        )
        data && (this.documents = data.documents || [])
      } catch (e) {
        console.error(e)
        alert('Při získávání dokumentú nastala chyba')
      }
    },
  },

  created() {
    this.invokeGetDocuments()
  },
})
