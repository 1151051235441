


















import Vue from 'vue'

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    address: {
      type: [String, Array],
      required: true,
    },
    contact: {
      type: [String, Array],
      required: true,
    },
  },
})
