<template>
  <component-loader :description="description"></component-loader>
</template>

<script>
import Vue from 'vue'
import ComponentLoader from './ComponentLoader'

export default Vue.extend({
  props: {
    description: {
      type: String,
      default: '<span></span>',
    },
  },

  components: {
    ComponentLoader,
  },
})
</script>

<style lang="scss">
@import '~@/scss/variables.scss';
.red {
  color: $red;
  display: inline;
}

.big {
  font-size: 40px;
}

.bold {
  font-weight: bold;
  font-size: 19px;
  line-height: 1.7em;
}
</style>
