import { Language } from './translations.typings'

export enum ChildRoute {
  INDEX = 'index',
  ABOUT = 'about',
  CAREER = 'career',
  NEWS = 'news',
  PRODUCTS = 'products',
  CONTACTS = 'contacts',
  CURIOSITY = 'curiosity',
  CONTACT_POINTS = 'contactPoints',
  CERTIFICATES = 'certificates',
  SERVICING = 'servicing',
  REFERENCE = 'reference',
  INTRANET = 'intranet',
}

export type Aliases = {
  // Exclude INDEX
  [key in Exclude<ChildRoute, ChildRoute.INDEX>]: {
    // Exclude EN (fallback path)
    [key in Exclude<Language, Language.EN>]: string
  }
}
