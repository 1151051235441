import Vue from 'vue'
import Vuex from 'vuex'
import { EventBus } from './utils/event-bus'
import { FerritState, User } from './typings/store.typings'
import { Language } from './typings/translations.typings'
import { Scope } from './utils/auth0.consts'
import { getLock } from './utils/auth0.client'
import { AxiosResponse } from 'axios'
import { ajax, secureAjax } from './utils/axios.helpers'

Vue.use(Vuex)

export default new Vuex.Store<FerritState>({
  state: {
    user: {
      isAuthenticated: false,
      isLoading: true,
      token: '',
      metadata: {},
    },
    careers: [],
    articles: [],
    bulletin: [],
    gallery: [],
  },

  mutations: {
    setUser(state, user: User) {
      state.user = user
    },

    refreshUserToken(state, token: string) {
      state.user.token = token
    },

    setCareers(state, payload) {
      state.careers = payload
    },

    setArticles(state, payload) {
      state.articles = payload
    },

    setBulletin(state, payload) {
      state.bulletin = payload
    },

    setGallery(state, payload) {
      state.gallery = payload.images
    },
  },

  actions: {
    // Perform authentication and fetch user metadata
    async getUser({ commit }, params: { [s: string]: string }) {
      if (process.env.VUE_ENABLE_GUEST_MODE) {
        commit('setUser', <User>{
          isAuthenticated: true,
          isLoading: false,
          token: "guest",
          metadata: {
            name: "guest@guest.local",
            picture: null,
          }
        })

        EventBus.$emit('isAuthorized')
        return;
      }

      const lock = getLock(params.scope, params.redirectPath)

      lock.show()
      lock.on('authenticated', auth => {
        const accessToken = auth.accessToken

        lock.hide()
        lock.getUserInfo(accessToken, (error, profile) => {
          commit('setUser', <User>{
            isAuthenticated: !error,
            isLoading: false,
            token: accessToken,
            metadata: profile
              ? {
                  name: profile.email,
                  picture: profile.picture,
                }
              : {},
          })

          EventBus.$emit('isAuthorized')
        })
      })
    },

    getCareers({ commit }, lang: Language | undefined) {
      return ajax({ url: '/career', params: lang ? { lang } : {} }).then((response: AxiosResponse) =>
        commit('setCareers', response.data)
      )
    },

    async getArticles({ commit }, lang: Language | undefined) {
      try {
        const { data } = await ajax({ url: '/article', params: lang ? { lang } : {} })
        commit('setArticles', data)
      } catch (e) {
        console.error(e)
      }
    },

    async getGallery({ commit }, lang: Language = Language.CS) {
      secureAjax(
        {
          url: '/gallery',
          params: { lang },
        },
        Scope.MANAGE_ADMIN
      ).then((response: AxiosResponse) => {
        commit('setGallery', response.data)
      })
    },

    async getBulletins({ commit }, lang: Language | undefined) {
      secureAjax(
        {
          url: '/bulletin',
          params: lang ? { lang } : {},
        },
        Scope.READ_ARTICLES
      ).then((response: AxiosResponse) => {
        commit('setBulletin', response.data)
      })
    },
  },
})
