











































import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ManageAction from '@/components/admin/ManageAction.vue'
import { secureAjax } from '@/utils/axios.helpers'
import { Scope } from '@/utils/auth0.consts'
import { adminManagementLanguages } from '@/utils/lang.consts'
import { CareerModel } from '@/typings/career.typings'

export default Vue.extend({
  name: 'AdminManageCareers',

  components: { FontAwesomeIcon, ManageAction },

  data() {
    return {
      lang: undefined,
      languages: adminManagementLanguages,
    }
  },

  computed: {
    articles(): Array<CareerModel> {
      return this.$store.state.articles
    },

    mappedLanguages(){
      const languages = this.languages as typeof adminManagementLanguages
      return languages.reduce(
        (mapped, item) => { mapped[item.value] = item.text; return mapped; },
        {} as Record<string, string>
      )
    },
  },

  watch: {
    lang: {
      immediate: true,
      handler() {
        this.invokeGetArticles()
      },
    },
  },

  methods: {
    async removeArticle(id: number) {
      try {
        await secureAjax(
          {
            method: 'DELETE',
            url: '/article',
            data: { id },
          },
          Scope.MANAGE_ADMIN
        )

        this.invokeGetArticles()
        alert(`Zpráva '${this.getTitleById(id)}' byla smazána`)
      } catch (e) {
        console.error(e)
        alert('Při zápisu nastala chyba')
      }
    },

    getTitleById(id: number): string {
      return this.articles.find(it => it.id === id).title as string
    },

    invokeGetArticles() {
      this.$store.dispatch('getArticles', this.lang)
    },
  },
})
