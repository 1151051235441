











































import Bar from '@/components/Bar.vue'
import { generateLocationWithRouteAlias } from '@/locale/routes'
import { LangMixin } from '@/mixins/lang.mixin'
import mixins from 'vue-typed-mixins'
import { footerRoutes } from '@/utils/router.helpers'
import { ChildRoute } from '@/typings/router.typings'
import BoxCarousel from '@/components/carousel/BoxCarousel.vue'

export default mixins(LangMixin).extend({
  name: 'CuriosityStack',

  components: {
    Bar,
    BoxCarousel,
  },

  methods: {
    nontechnicalImage(idx: number) {
      return `curiosity_stack/nontechnical/image${idx + 1}.${
        1 === idx + 1 ? 'jpg' : 'png'
      }`
    },
    technicalImage(idx: number) {
      return `curiosity_stack/technical/image${idx + 1}.${
        [8, 6, 5, 3].includes(idx + 1) ? 'jpg' : 'png'
      }`
    },
  },

  computed: {
    technical() {
      return Object.values(this.$t('curiosities.technical')).map((it, idx) => ({
        //@ts-ignore
        image: this.technicalImage(idx),
        description: it,
      }))
    },
    nontechnical() {
      return Object.values(this.$t('curiosities.nontechnical')).map(
        (it, idx) => ({
          //@ts-ignore
          image: this.nontechnicalImage(idx),
          description: it,
        })
      )
    },
  },
})
