














import Vue from 'vue'

export default Vue.extend({
  name: 'AdminDefault',

  data() {
    return {
      markdownExample: `Hlavní nadpis
==========

* toto
* je
* seznam

Menší podnadpis
---------------

1. toto
2. se čísluje
1. ale na číslech nezáleží

# Hlavní nadpis jinak

## Menší nadpis jinak

### Ještě menší nadpis jinak

Odstavce se oddělují
prázdným řádkem. Na délce řádků nezáleží

Udělám nový odstavec. Ještě odkaz na
[dokumentaci](http://daringfireball.net/projects/markdown/syntax)

Horizontální oddělovač:
---

Vlastnosti textu _kurzíva_, *kurzíva*, __tučně__, **tučně**, \`neproporcionální\`.

<p>Dle libosti ''můžu používat'' html</p>`,
    }
  },
})
