










import mixins from 'vue-typed-mixins'
import TextComponent from '@/components/TextComponent.vue'
import { Pages } from '@/typings/translations.typings'
import { PageMixin } from '@/mixins/page.mixin'
import { TitleMixin } from '@/mixins/title.mixin'

export default mixins(TitleMixin, PageMixin).extend({
  name: 'Reference',

  htmlTitle: Pages.reference,

  components: {
    TextComponent,
  },
})
