import { Route } from 'vue-router'
import i18n from '@/i18n'
import { Language } from '@/typings/translations.typings'
import { ChildRoute } from '@/typings/router.typings'

export const languageGuard = (
  to: Route,
  fallback: string,
  next: (to?: string) => void
) => {
  const lang = to.params.lang

  if (!(lang.toUpperCase() in Language)) {
    return next(`/${fallback}${Language.CS}`)
  }

  if (i18n.locale !== lang) {
    i18n.locale = lang
  }

  return next()
}

// footer routes
export const footerRoutes = [
  ChildRoute.ABOUT,
  ChildRoute.PRODUCTS,
  ChildRoute.NEWS,
  ChildRoute.CURIOSITY,
  ChildRoute.CAREER,
  ChildRoute.CONTACT_POINTS,
  ChildRoute.CONTACTS,
  ChildRoute.CERTIFICATES,
  // ChildRoute.SERVICING,
  // ChildRoute.REFERENCE,  //fixme: hidden for now
  // ChildRoute.INDEX,
  ChildRoute.INTRANET,
]
