






























import Vue from 'vue'
import InlineCircles from '@/components/InlineCircles.vue'
import DescriptionBox from '@/components/DescriptionBox.vue'

interface BoxCarouselData {
  current: number
}

export default Vue.extend({
  name: 'BoxCarousel',

  components: {
    InlineCircles,
    DescriptionBox,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data(): BoxCarouselData {
    return {
      current: 0,
    }
  },
})
