




































































import mixins from 'vue-typed-mixins'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Brand from '@/components/Brand.vue'
import EmbedMap from '@/components/EmbedMap.vue'
import { Pages } from '@/typings/translations.typings'
import { TitleMixin } from '@/mixins/title.mixin'

export default mixins(TitleMixin).extend({
  name: 'Contacts',

  htmlTitle: Pages.contacts,

  components: {
    Brand,
    EmbedMap,
    FontAwesomeIcon,
  },
})
