









import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default Vue.extend({
  name: 'ManageAction',

  components: { FontAwesomeIcon },

  props: {
    icon: {
      type: String,
      default: '',
    },
  },
})
