import { Location } from 'vue-router'
import { oc } from 'ts-optchain'
import { Language } from '@/typings/translations.typings'
import { Aliases, ChildRoute } from '@/typings/router.typings'

const aliases: Aliases = {
  [ChildRoute.ABOUT]: {
    [Language.CS]: 'o-nas',
    [Language.RU]: 'o-nas',
    // [Language.ES]: 'sobre-nosotros',
  },

  [ChildRoute.CAREER]: {
    [Language.CS]: 'kariera',
    [Language.RU]: 'kariera',
    // [Language.ES]: 'carrera',
  },

  [ChildRoute.PRODUCTS]: {
    [Language.CS]: 'produkty',
    [Language.RU]: 'produkty',
    // [Language.ES]: 'productos',
  },

  [ChildRoute.NEWS]: {
    [Language.CS]: 'novinky',
    [Language.RU]: 'novosti',
    // [Language.ES]: 'noticias',
  },

  [ChildRoute.CONTACTS]: {
    [Language.CS]: 'kontakty',
    [Language.RU]: 'kontakty',
    // [Language.ES]: 'contactos',
  },

  [ChildRoute.CURIOSITY]: {
    [Language.CS]: 'zajimavosti',
    [Language.RU]: 'interesnye-fakty',
    // [Language.ES]: 'curiosidades',
  },

  [ChildRoute.CONTACT_POINTS]: {
    [Language.CS]: 'pobocky',
    [Language.RU]: 'filialy',
    // [Language.ES]: 'oficinas',
  },

  [ChildRoute.CERTIFICATES]: {
    [Language.CS]: 'certifikaty',
    [Language.RU]: 'sertifikaty',
    // [Language.ES]: 'certificados',
  },

  [ChildRoute.SERVICING]: {
    [Language.CS]: 'servis',
    [Language.RU]: 'servis',
    // [Language.ES]: 'servicio-tecnico',
  },

  [ChildRoute.REFERENCE]: {
    [Language.CS]: 'reference',
    [Language.RU]: 'spravki',
    // [Language.ES]: 'referencia',
  },

  [ChildRoute.INTRANET]: {
    [Language.CS]: 'intranet',
    [Language.RU]: 'intranet',
    // [Language.ES]: 'intranet',
  },
}

export function getRouteAliases(route: Exclude<ChildRoute, ChildRoute.INDEX>) {
  return [...new Set(Object.values(aliases[route]).filter(r => r !== route))]
}

export function getRouteAliasByLanguage(
  route: Exclude<ChildRoute, ChildRoute.INDEX>,
  lang: Exclude<Language, Language.EN>
) {
  const defaultFallback = <any>route === ChildRoute.INDEX ? '' : route

  return oc(aliases)[route][lang](defaultFallback)
}

export function generateLocationWithRouteAlias(
  route: Exclude<ChildRoute, ChildRoute.INDEX>,
  lang: Exclude<Language, Language.EN>
) {
  if (route === ChildRoute.INTRANET) {
    return <Location>{
      path: `/${getRouteAliasByLanguage(route, lang)}/${lang}`,
    }
  }
  return <Location>{
    path: `/${lang}/${getRouteAliasByLanguage(route, lang)}`,
  }
}
