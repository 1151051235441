import { VideoEnum, Video } from '@/typings/video.typings'
import i18n from '@/i18n'

export const video = <Video>{
  [VideoEnum.CAREER]: {
    icon: 'play-circle',
    url: 'https://www.youtube.com/watch?v=oMISnEHDhhY',
    description: i18n.t('hrVideoDescription'),
  },

  [VideoEnum.ABOUT]: {
    icon: 'play-circle',
    url: 'https://www.youtube.com/watch?v=oMISnEHDhhY',
    description: i18n.t('hrVideoDescription'),
  },
}
