

















































import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ManageAction from '@/components/admin/ManageAction.vue'
import { secureAjax } from '@/utils/axios.helpers'
import { Scope } from '@/utils/auth0.consts'
import { adminManagementLanguages } from '@/utils/lang.consts'
import { CareerModel } from '@/typings/career.typings'

export default Vue.extend({
  name: 'AdminManageCareers',

  components: { FontAwesomeIcon, ManageAction },

  data() {
    return {
      lang: undefined,
      languages: adminManagementLanguages,
    }
  },

  computed: {
    careers(): Array<CareerModel> {
      return this.$store.state.careers
    },

    mappedLanguages(){
      const languages = this.languages as typeof adminManagementLanguages
      return languages.reduce(
        (mapped, item) => { mapped[item.value] = item.text; return mapped; },
        {} as Record<string, string>
      )
    },
  },

  watch: {
    lang: {
      immediate: true,
      handler() {
        this.invokeGetCareers()
      },
    },
  },

  methods: {
    async removeCareer(id: number) {
      try {
        await secureAjax(
          {
            method: 'DELETE',
            url: '/career',
            data: { id },
          },
          Scope.MANAGE_ADMIN
        )

        this.invokeGetCareers()
        alert(`Kariéra '${this.getTitleById(id)}' byla smazána`)
      } catch (e) {
        console.error(e)
        alert('Při zápisu nastala chyba')
      }
    },

    getTitleById(id: number): string {
      return this.careers.find(it => it.id === id).title as string
    },

    invokeGetCareers() {
      this.$store.dispatch('getCareers', this.lang)
    },
  },
})
