


















import { Location } from 'vue-router'
import mixins from 'vue-typed-mixins'
import { generateLocationWithRouteAlias } from '@/locale/routes'
import { LangMixin } from '@/mixins/lang.mixin'
import { PrettyLanguage, Language } from '@/typings/translations.typings'

export default mixins(LangMixin).extend({
  name: 'LanguageChanger',

  computed: {
    routeName(): string {
      return this.$route.name || ''
    },

    languages() {
      return PrettyLanguage
    },

    currentLanguage(): string {
      return (this.lang as string).toUpperCase()
    },
  },

  methods: {
    createLangParam(language: string): Location {
      const lang = <any>language
      const routeName = <any>this.routeName

      return generateLocationWithRouteAlias(routeName, lang)
    },

    changeLanguage(key: string) {
      this.$i18n.locale = key
    },
  },
})
