import { render, staticRenderFns } from "./CuriosityStack.vue?vue&type=template&id=5bc05c62&scoped=true&"
import script from "./CuriosityStack.vue?vue&type=script&lang=ts&"
export * from "./CuriosityStack.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./CuriosityStack.vue?vue&type=style&index=0&id=5bc05c62&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5bc05c62",
  "9f02cff6"
  
)

export default component.exports