import { Language, Locale } from '../../typings/translations.typings'
import { translations as csTls } from './cs.consts'
import { translations as enTls } from './en.consts'
import { translations as ruTls } from './ru.consts'
// import { translations as esTls } from './es.consts'

export const messages: Locale = {
  [Language.CS]: csTls,
  [Language.EN]: enTls,
  [Language.RU]: ruTls,
  // [Language.ES]: esTls,
}
