import { Language, LanguageFlags } from '@/typings/translations.typings'

export const adminLanguages = [
  { value: Language.CS, text: `${LanguageFlags[Language.CS]} Česky (${Language.CS})` },
  { value: Language.EN, text: `${LanguageFlags[Language.EN]} Anglicky (${Language.EN})` },
  { value: Language.RU, text: `${LanguageFlags[Language.RU]} Rusky (${Language.RU})` },
  // { value: Language.ES, text: `${LanguageFlags[Language.ES]} Španělsky (${Language.ES})` },
]

export const adminManagementLanguages = [
  { value: undefined, text: '🌍 Všechno' },
  ...adminLanguages
]
