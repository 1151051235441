import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import App from './App.vue'
import { createRouter } from './router'
import store from './store'
import i18n from './i18n'
import { Language } from './typings/translations.typings'
import './vee-validate'
import 'purecss/build/pure-min.css'
import 'purecss/build/grids-core-min.css'
import '@/scss/main.scss'

library.add(fas)

// SSR-incompatible plugins and global components
if (typeof window !== 'undefined') {
  const AsyncComputed = require('vue-async-computed').default
  const VueSilentbox = require('vue-silentbox').default
  const MarkdownEditor = require('@jailers-oss/internal-mde').default

  Vue.use(AsyncComputed)
  Vue.use(VueSilentbox)
  Vue.component('markdown-editor', MarkdownEditor)
}

Vue.config.productionTip = false

export function createApp(lang?: Language | string) {
  const router = createRouter()

  const app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  })

  if (lang) {
    i18n.locale = lang
  }

  return { app, router }
}
