








































































































import Vue from 'vue'
import Btn from './Btn.vue'
import ComponentLoader from './ComponentLoader.vue'
import DescriptionBox from '@/components/DescriptionBox.vue'

export default Vue.extend({
  components: {
    Btn,
    ComponentLoader,
    DescriptionBox,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },

    image: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },

    video: {
      type: Object,
      default: () => ({ url: '' }),
    },

    link: {
      type: Object,
      default: () => ({ title: '', path: '', icon: null } as object),
    },

    hasBorder: {
      type: Boolean,
      default: true,
    },

    height: {
      type: [String, Number],
      default: '625',
    },

    headingElement: {
      type: String,
      default: 'h1',
    },

    angleSide: {
      type: String,
      default: 'bottom',
    },

    grid: {
      type: Array,
      default: () => ['7-12', '5-12'],
    },

    hasDescriptionBox: {
      type: Boolean,
      default: false,
    },

    isVertical: {
      type: Boolean,
      default: false,
    },

    swapColumns: {
      type: Boolean,
      default: false,
    },

    whiteBackground: {
      type: Boolean,
      default: false,
    },

    hasRectangle: {
      type: Boolean,
      default: true,
    },

    hasContainBackgorund: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOverlayActive: false,
    }
  },

  watch: {
    "video.url": {
      immediate: true,
      handler(url: string) {
        if (!url) {
          return
        }

        this.$nextTick(() => {
          if (!this.$refs.video) {
            return
          }

          this.$watch(
            // @ts-ignore
            () => this.$refs.video.overlayVisibility,
            (isActive: boolean) => {
              this.isOverlayActive = isActive
            }
          )
        })
      },
    }
  },
})
