import Auth0Lock from 'auth0-lock'
import { GLOBAL_AUDIENCE } from './auth0.consts'
import i18n from '@/i18n'
import store from '@/store'

export function getLock(scope: string, redirectPath: string) {
  const baseUrl = typeof window !== 'undefined' ? window.location.origin : ''

  return new Auth0Lock(
    '0DmbnvWWN38lT9lx3bEXTF8Aopzv7hil',
    'ferrit-electric.eu.auth0.com',
    {
      auth: {
        redirectUrl: `${baseUrl}${redirectPath}`,
        responseType: 'token',
        audience: GLOBAL_AUDIENCE,
        params: {
          scope: `openid email ${scope}`,
        },
      },
      language: i18n.locale,
      languageDictionary: {
        title: i18n.t('loginModal.title'),
        emailInputPlaceholder: i18n.t('loginModal.emailPlaceholder'),
        passwordInputPlaceholder: i18n.t('loginModal.passwordPlaceholder'),
        forgotPasswordAction: i18n.t('loginModal.forgotPasswordAction'),
        forgotPasswordInstructions: i18n.t(
          'loginModal.forgotPasswordInstructions'
        ),
        lastLoginInstructions: i18n.t('loginModal.lastLoginInstructions'),
        notYourAccountAction: i18n.t('loginModal.notYourAccountAction'),
        success: {
          forgotPassword: i18n.t('loginModal.successForgotPassword'),
        },
      },
      theme: {
        logo: 'https://ferrit-electric.cz/static/logo.png',
        primaryColor: '#e30613',
      },
    }
  )
}

export async function refreshToken(scope: string) {
  const lock = getLock(scope, '')

  return new Promise(resolve => {
    lock.checkSession(
      {
        params: {
          scope: `openid email ${scope}`,
        },
      },
      (error: any, auth: { [s: string]: any }) => {
        if (!error) {
          store.commit('refreshUserToken', auth.accessToken)
        }
        resolve(null)
      }
    )
  })
}
