










































































import { Location } from 'vue-router'
import mixins from 'vue-typed-mixins'
import Icon from './Icon.vue'
import LanguageChanger from './LanguageChanger.vue'
import LanguageChangerSimple from './LanguageChangerSimple.vue'
import { LangMixin } from '@/mixins/lang.mixin'
import { generateLocationWithRouteAlias } from '@/locale/routes'
import { ChildRoute } from '@/typings/router.typings'
import { DeviceDetectMixin } from '@/mixins/device-detector.mixin'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default mixins(LangMixin, DeviceDetectMixin).extend({
  name: 'navigation',

  components: {
    Icon,
    LanguageChanger,
    LanguageChangerSimple,
    FontAwesomeIcon,
  },

  data() {
    return {
      isLanguageDropdownVisible: true,
      isMenuOpened: false,
    }
  },

  watch: {
    $route(to, from) {
      this.isMenuOpened = false
    },
  },

  computed: {
    showLanguageChanger() {
      return true // Set to false to hide the switcher
    },

    indexRoute(): Location {
      return { name: 'index', params: { lang: this.lang } }
    },

    routes(): { [key: string]: Location } {
      const lang = <any>this.lang

      return {
        about: generateLocationWithRouteAlias(ChildRoute.ABOUT, lang),
        products: generateLocationWithRouteAlias(ChildRoute.PRODUCTS, lang),
        news: generateLocationWithRouteAlias(ChildRoute.NEWS, lang),
        curiosity: generateLocationWithRouteAlias(ChildRoute.CURIOSITY, lang),
        career: generateLocationWithRouteAlias(ChildRoute.CAREER, lang),
        contacts: generateLocationWithRouteAlias(ChildRoute.CONTACTS, lang),
      }
    },

    mobileRoutes(): Array<{
      name: ChildRoute | string
      route: Location
      isStandalone: boolean
    }> {
      const lang = <any>this.lang

      return [
        {
          name: ChildRoute.PRODUCTS,
          route: generateLocationWithRouteAlias(ChildRoute.PRODUCTS, lang),
          isStandalone: true,
        },
        // {
        //   name: ChildRoute.SERVICING,
        //   route: generateLocationWithRouteAlias(ChildRoute.SERVICING, lang),
        //   isStandalone: false,
        // },
        {
          name: ChildRoute.CERTIFICATES,
          route: generateLocationWithRouteAlias(ChildRoute.CERTIFICATES, lang),
          isStandalone: false,
        },
        // {
        //   name: ChildRoute.REFERENCE,
        //   route: generateLocationWithRouteAlias(ChildRoute.REFERENCE, lang),
        //   isStandalone: true,
        // },
        {
          name: ChildRoute.ABOUT,
          route: generateLocationWithRouteAlias(ChildRoute.ABOUT, lang),
          isStandalone: true,
        },
        {
          name: ChildRoute.NEWS,
          route: generateLocationWithRouteAlias(ChildRoute.NEWS, lang),
          isStandalone: false,
        },
        {
          name: ChildRoute.CURIOSITY,
          route: generateLocationWithRouteAlias(ChildRoute.CURIOSITY, lang),
          isStandalone: false,
        },
        {
          name: ChildRoute.CAREER,
          route: generateLocationWithRouteAlias(ChildRoute.CAREER, lang),
          isStandalone: true,
        },
        {
          name: ChildRoute.CONTACTS,
          route: generateLocationWithRouteAlias(ChildRoute.CONTACTS, lang),
          isStandalone: true,
        },
        // {
        //   name: 'domicile',
        //   route: generateLocationWithRouteAlias(ChildRoute.CONTACTS, lang),
        //   isStandalone: false,
        // },
        {
          name: ChildRoute.CONTACT_POINTS,
          route: generateLocationWithRouteAlias(
            ChildRoute.CONTACT_POINTS,
            lang
          ),
          isStandalone: false,
        },
      ]
    },
  },

  methods: {
    toggleMobileMenu() {
      this.isMenuOpened = !this.isMenuOpened
    },
  },
})
