import Vue from 'vue'
import { Language } from '@/typings/translations.typings'

const getTitle = (vm: Vue) => {
  const { htmlTitle } = vm.$options
  return htmlTitle instanceof Function ? htmlTitle.call(vm) : htmlTitle
}

const stripHtml = (input: string, titleFullPrefix: string, titlePartialPrefix: string): string => {
  return input
    .replace(/<[brand ]+?\/*>/g, titleFullPrefix)
    .replace(/<[brand ]+?:.*e" ?\/*>/g, titlePartialPrefix)
    .replace(/<[^>]+>/g, '')
    .trim()
}

const serverTitleMixin = Vue.extend({
  created() {
    const htmlTitle = getTitle(this)
    const titleFullPrefix = this.$ssrContext.lang === Language.RU ? 'Феррит Электрик' : 'Ferrit Electric'
    const titlePartialPrefix = this.$ssrContext.lang === Language.RU ? 'Феррит' : 'Ferrit'
    const page = this.$options.name.toLowerCase()

    this.$ssrContext.description = stripHtml(
      this.$t(`bar.${htmlTitle === 'index' ? 'index' : page}`) as string,
      titleFullPrefix,
      titlePartialPrefix
    )

    if (htmlTitle) {
      this.$ssrContext.title = `${titleFullPrefix}${
        htmlTitle === 'index' ? '' : ' | '
      }${this.$t(`htmlTitle.${htmlTitle}`)}`
    }
  },
})

const clientTitleMixin = Vue.extend({
  mounted() {
    const htmlTitle = getTitle(this)
    const lang = this.$route.params.lang || Language.CS
    const titlePrefix = lang === Language.RU ? 'Феррит Электрик' : 'Ferrit Electric'

    if (htmlTitle) {
      document.title = `${titlePrefix}${
        htmlTitle === 'index' ? '' : ' | '
      }${this.$t(`htmlTitle.${htmlTitle}`)}`
    }
  },
})

export const TitleMixin =
  process.env.VUE_ENV === 'server' ? serverTitleMixin : clientTitleMixin
