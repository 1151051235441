













import Vue from 'vue'
import RouteGuard from '@/components/RouteGuard.vue'
import AdminNavigation from '@/components/admin/AdminNavigation.vue'
import UserWidget from '@/components/admin/UserWidget.vue'
import { Scope } from '@/utils/auth0.consts'

export default Vue.extend({
  name: 'Admin',

  components: { RouteGuard, AdminNavigation, UserWidget },

  computed: {
    heading(): string {
      return this.$route.meta.heading || 'Administrace'
    },

    scope(): Scope {
      return Scope.MANAGE_ADMIN
    },
  },
})
