





















import mixins from 'vue-typed-mixins'
import RouteGuard from '@/components/RouteGuard.vue'
import UserWidget from '@/components/admin/UserWidget.vue'
import Brand from '@/components/Brand.vue'
import { LangMixin } from '@/mixins/lang.mixin'
import { PrettyDate } from '@/mixins/date.mixin'
import { TitleMixin } from '@/mixins/title.mixin'
import { Scope } from '@/utils/auth0.consts'
import { EventBus } from '@/utils/event-bus'
import { LanguageFlags, Language, Pages } from '@/typings/translations.typings'
import { BulletinModel } from '@/typings/bulletin.typings'

export default mixins(TitleMixin, LangMixin, PrettyDate).extend({
  name: 'Intranet',

  htmlTitle: Pages.intranet,

  components: { RouteGuard, UserWidget, Brand },

  computed: {
    scope(): Scope {
      return Scope.READ_ARTICLES
    },

    currentLanguageFlag(): string {
      const lang = this.lang as Language
      return LanguageFlags[lang]
    },

    bulletin(): Array<BulletinModel> {
      return this.$store.state.bulletin
    },
  },

  created() {
    EventBus.$on('isAuthorized', () => {
      this.$store.dispatch('getBulletins', this.lang)
    })
  },
})
