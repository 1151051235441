









import mixins from 'vue-typed-mixins'
import { LangMixin } from '@/mixins/lang.mixin'
import { Language } from '@/typings/translations.typings'

export default mixins(LangMixin).extend({
  name: 'brand',

  props: {
    prefix: {
      type: String,
      required: false,
      default: '',
    },

    suffix: {
      type: String,
      required: false,
      default: '',
    },

    ferritColor: {
      type: String,
      default: '',
    },

    excludeElectric: {
      type: Boolean,
      default: false,
    },

    forceLatin: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    brandName(): [string, string] {
      if (this.lang === Language.RU && !this.forceLatin) {
        return ["ФЕРРИТ", "ЭЛЕКТРИК"]
      }

      return ["FERRIT", "ELECTRIC"]
    },
  },
})
