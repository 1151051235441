import { render, staticRenderFns } from "./ManageCareers.vue?vue&type=template&id=2804361e&"
import script from "./ManageCareers.vue?vue&type=script&lang=ts&"
export * from "./ManageCareers.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  "6f2c3ed8"
  
)

export default component.exports