<template>
  <div class="bar" :style="{ height: `${height}px` }">
    <div class="bar-item pure-u-1 pure-u-md-5-12">
      <div class="rectangle-wrapper">
        <div class="content with-description-box is-vertical">
          <router-link :to="{ name: link }" :title="title">
            <description-box
              class="bar-description-box"
              :title="title"
              :description="description"
              :has-triangle="false"
              :no-padding="false"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import ComponentLoader from './ComponentLoader'
import DescriptionBox from '@/components/DescriptionBox.vue'

export default Vue.extend({
  name: 'BoxLink',
  props: {
    title: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },

    link: {
      type: String,
      required: true,
    },

    height: {
      type: [String, Number],
      default: '625',
    },
  },

  components: {
    ComponentLoader,
    DescriptionBox,
  },
})
</script>

<style scoped lang="scss">
@import '~@/scss/variables.scss';

.pure-g {
  overflow: hidden;
}

a,
a:link,
a:visited {
  color: inherit;
  text-decoration: inherit;
}

a::v-deep h2.title {
  text-decoration: underline $red !important;
}

.bar {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: $blue;
  color: white;
  flex-direction: column;

  .description {
    line-height: 32px;
    font-size: 18px;
  }

  .bar-item {
    height: 100%;
  }
  @media screen and (max-width: 48em) {
    .bar-item:first-of-type {
      height: 30%;
    }
    .bar-item {
      height: 70%;
    }
  }
}
// mobile rule
@media screen and (max-width: 48em) {
  .pull-right {
    margin: auto;
  }
  .bar {
    height: auto !important;
  }

  .btn {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .content {
    .rectangle {
      display: none;
    }
  }
}

// more than mobile
@media screen and (min-width: 48em) {
  h1,
  h2 {
    margin-top: 95px;
    margin-bottom: 1.5em;
    line-height: 60px;
  }

  h1 {
    font-size: 60px;
  }
}

h2 {
  font-size: 40px;
}

.content {
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 90%;
  padding: 1em;

  &.with-description-box {
    padding-top: 2em;
  }

  .bar-description-box {
    width: 100%;
  }
}
</style>
