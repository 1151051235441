
































































































































import mixins from 'vue-typed-mixins'
import TextComponent from '@/components/TextComponent.vue'
import Icon from '@/components/Icon.vue'
import TimeLineWrapper from '@/components/TimeLineWrapper.vue'
import { Advantages } from '@/typings/translations.typings'
import { Pages } from '@/typings/translations.typings'
import { PageMixin } from '@/mixins/page.mixin'
import { TitleMixin } from '@/mixins/title.mixin'

export default mixins(TitleMixin, PageMixin).extend({
  name: 'About',

  htmlTitle: Pages.about,

  components: {
    TextComponent,
    Icon,
    TimeLineWrapper,
  },

  data() {
    return {
      logos: ['suspension', 'rails', 'lacustrian', 'lifting', 'electric'],
      advantages: ['01-02', '03', '04', '05', '06'],
    }
  },

  computed: {
    advantagesDescription(): Advantages {
      return this.$t('page.about.advantages') as Advantages
    },
  },
})
