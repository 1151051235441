











import Bar from '@/components/Bar.vue'
import { ChildRoute } from '@/typings/router.typings'
import { generateLocationWithRouteAlias } from '@/locale/routes'
import { LangMixin } from '@/mixins/lang.mixin'
import mixins from 'vue-typed-mixins'

export default mixins(LangMixin).extend({
  name: 'LookingForBar',

  components: {
    Bar,
  },

  computed: {
    link() {
      const lang = <any>this.lang
      return {
        path: generateLocationWithRouteAlias(ChildRoute.CAREER, lang).path,
        title: this.$t('openPositions'),
        icon: 'user-friends',
      }
    },
  },
})
