import { render, staticRenderFns } from "./ManageOfficeDocuments.vue?vue&type=template&id=0d0dd855&scoped=true&"
import script from "./ManageOfficeDocuments.vue?vue&type=script&lang=ts&"
export * from "./ManageOfficeDocuments.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./ManageOfficeDocuments.vue?vue&type=style&index=0&id=0d0dd855&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "0d0dd855",
  "379f9cbb"
  
)

export default component.exports