import { TranslateResult } from 'vue-i18n'

export enum VideoEnum {
  CAREER = 'career',
  ABOUT = 'about',
}

export type VideoItem = {
  icon: string
  url: string
  description: TranslateResult | string
}

export type Video = {
  [key in VideoEnum]: VideoItem
}
