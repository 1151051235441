












import Vue from 'vue'

export default Vue.extend({
  name: 'InlineCircles',

  props: {
    count: {
      type: Number,
      required: true,
    },

    activeIndex: {
      type: Number,
      required: true,
    },
  },
})
