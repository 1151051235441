






import Vue from 'vue'
import AddBulletinForm from '@/components/admin/AddBulletinForm.vue'

export default Vue.extend({
  name: 'AdminAddBulletin',

  components: { AddBulletinForm },
})
