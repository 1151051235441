















































import Brand from './Brand.vue'
import { generateLocationWithRouteAlias } from '@/locale/routes'
import { ChildRoute } from '@/typings/router.typings'
import { Location } from 'vue-router'
import mixins from 'vue-typed-mixins'
import { LangMixin } from '@/mixins/lang.mixin'
import { footerRoutes } from '@/utils/router.helpers'

export default mixins(LangMixin).extend({
  components: {
    Brand,
  },

  computed: {
    routes(): Array<{ name: string; route: Location }> {
      const lang = <any>this.lang

      return footerRoutes.map(it => ({
        name: it === 'index' ? 'quickSection' : (it as string),
        route: generateLocationWithRouteAlias(
          it as Exclude<ChildRoute, ChildRoute.INDEX>,
          lang
        ),
      }))
    },
  },

  methods: {
    column(index: number) {
      const amount: number = Object.keys(this.routes).length
      const half: number = Math.floor(amount / 2)
      const from: number = index == 0 ? 0 : half
      const to: number = index == 0 ? half : amount

      return this.routes.slice(from, to)
    },
  },
})
