import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { messages } from './utils/translations'
import { Language } from './typings/translations.typings'

Vue.use(VueI18n)

export default new VueI18n({
  fallbackLocale: Language.CS,
  messages,
})
