import { render, staticRenderFns } from "./Contacts.vue?vue&type=template&id=3c26a7be&scoped=true&"
import script from "./Contacts.vue?vue&type=script&lang=ts&"
export * from "./Contacts.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./Contacts.vue?vue&type=style&index=0&id=3c26a7be&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "3c26a7be",
  "78be0fbf"
  
)

export default component.exports