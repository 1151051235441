var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"pure-form pure-form-aligned"},[_c('fieldset',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('div',{staticClass:"pure-control-group"},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"title"}},[_vm._v("Titulek")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"}],attrs:{"id":"title","type":"text","placeholder":"Titulek"},domProps:{"value":(_vm.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.title=$event.target.value}}}),_c('span',{staticClass:"pure-form-message-inline"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"pure-control-group"},[_c('validation-provider',{attrs:{"name":"image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"image"}},[_vm._v("Obrázek")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.image),expression:"image"}],attrs:{"id":"image"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.image=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.gallery),function(option,index){return _c('option',{key:index,domProps:{"value":option.value}},[_vm._v("\n              "+_vm._s(option.text)+"\n            ")])}),0),_c('img',{staticClass:"image",attrs:{"src":_vm.base + '/static/img/' + _vm.image,"alt":"Náhled"}}),_c('span',{staticClass:"pure-form-message-inline"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"pure-control-group"},[_c('validation-provider',{attrs:{"name":"lang","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"lang"}},[_vm._v("Jazyk")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.lang),expression:"lang"}],attrs:{"id":"lang"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.lang=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.languages),function(option,index){return _c('option',{key:index,domProps:{"value":option.value}},[_vm._v("\n              "+_vm._s(option.text)+"\n            ")])}),0),_c('span',{staticClass:"pure-form-message-inline"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('markdown-editor',{attrs:{"locale":"cs"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('div',{staticClass:"pure-controls"},[_c('button',{staticClass:"pure-button pure-button-primary",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.sendForm($event)}}},[_vm._v("\n          Submit\n        ")])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }