




























import Vue from 'vue'
import { md } from '@/utils/md-renderer'
import Icon from '@/components/Icon.vue'
import ComponentLoader from '@/components/ComponentLoader.vue'
import DescriptionBox from '@/components/DescriptionBox.vue'
import { runningInProductionMode } from '@/utils/mode'

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },

    date: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },

    image: {
      type: String,
      required: true,
    },

    video: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      isPlaying: true,
    }
  },

  computed: {
    parsedMd(): string {
      return `<span class="flex-col">${md.render(this.description)}</span>`
    },

    base(): string {
      return runningInProductionMode ? '' : 'http://localhost:3300'
    },
  },

  components: {
    Icon,
    ComponentLoader,
    DescriptionBox,
  },
})
