











import Vue from 'vue'

const EMBED_API_KEY = 'AIzaSyDS32tlZnGogfJT4zUdIsH5S7413Cc_d24'

export default Vue.extend({
  name: 'EmbedMap',

  props: {
    placeId: {
      type: String,
      required: true,
    },
  },

  computed: {
    mapSource(): string {
      return `https://www.google.com/maps/embed/v1/place?q=place_id:${this.placeId}&key=${EMBED_API_KEY}`
    },
  },
})
